import React from 'react';
import spinner from '../../assets/svg/spinner.svg';
import IntlTelInput from 'react-intl-tel-input';

import 'react-intl-tel-input/dist/main.css';



const toType = (obj) => {
  return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}

export default function CreateEducator(props) {
  const { first_nameError, last_nameError, emailError, phone_noError, serverError, makeResquest, handleChange, createProfile, closeForm, handlePhoneNo } = props;
  return (
    <React.Fragment>
      <div className="create-profile">
        <h3>Create Eduactor Profile</h3>
        <form action="">
          <div className="form-row">
            <label>
              First name
                    <input type="text" name="first_name" onChange={handleChange} />
              <p className="error-text">{first_nameError}</p>
            </label>
            <label>
              Last name
                    <input type="text" name="last_name" onChange={handleChange} />
              <p className="error-text">{last_nameError}</p>
            </label>
          </div>
          <label>
            Email Address
                  <input type="text" name="email" onChange={handleChange} />
            <p className="error-text">{emailError}</p>
          </label>
          {/* {
            type === 'parent' &&
            <React.Fragment>
              <label>
                Country
                      <select name="country" onChange={handleChange}>
                  <option value="">Nigeria</option>
                </select>
                <p className="error-text">{countryError}</p>
              </label>
              <label>
                Address
                    <input type="text" name="address" onChange={handleChange} />
                <p className="error-text">{addressError}</p>
              </label>
            </React.Fragment>
          } */}

          <label>
            Phone number
            <IntlTelInput
              preferredCountries={['ng', 'rw', 'ke', 'ug']}
              separateDialCode={true}
              useMobileFullscreenDropdown={false}
              formatOnInit={false}
              onPhoneNumberChange={handlePhoneNo}
            />
            <p className="error-text">{phone_noError}</p>
          </label>
          <div className="btn-div">
            {
              makeResquest &&
              <img src={spinner} alt="" />
            }
            {
              serverError && toType(serverError) === toType([]) ?
                serverError.map(item => {
                  const { message } = item;
                  return (
                    <p className="error-text">{message}</p>
                  )
                })
                :
                <p className="error-text">{serverError && serverError.message}</p>
            }
            <button className="button-green" onClick={createProfile}>CREATE PROFILE</button>
            <p onClick={closeForm} className="cancel">cancel</p>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}
