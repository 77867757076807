import React, { Component } from 'react';
import moment from 'moment';
import 'moment-timezone';
import Header from '../header/header';
import helper from '../helper';
import close_button from '../../assets/svg/small-close-button.svg';
import arrow_down from '../../assets/svg/arrow-down.svg';
import spinner from '../../assets/svg/spinner.svg';
import green_arrow_down from '../../assets/svg/green-arrow-down.svg';
import success_icon from '../../assets/svg/success-icon.svg';
import Modal from '../modal/Modal';
import { baseUrl } from '../url';

export default class ViewSession extends Component {
  state = {
    session: [],
    educators: [],
    response: false,
    showOptions: false,
    educatorId: '',
    makeResquest: false,
    comments: false,
  }
  componentDidMount() {
    const { match: { params } } = this.props;
    const { id } = params;
    this.fetchSession(id);
    this.fetchEducators();
  }

  fetchSession = async (id) => {
    // this.setState({
    //   response: false,
    // })
    const query = `{
      session(id:"${id}"){
        _id,
        child{
          first_name,
          last_name,
        },
        activity{
          name,
          description,
          category{
            name
          },
          ageGroup{
            min,
            max
          },
        },
        center{
          name,
          address,
          timezone,
        },
        educator{
          _id,
          first_name,
          last_name,
        }
        start,
        end,
        status,
        report,
        media{
          url
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    const { session } = data;
    const { educator } = session;
    await this.setState({
      session: [session],
      educatorId: educator ? educator._id : '',
      response: true,
    })
  }

  fetchEducators = async () => {
    const query = `{
      users(type: EDUCATOR){
        collection{
          _id,
          first_name,
          last_name,
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    const { users } = data;
    const { collection } = users;
    await this.setState({
      educators: collection,
      response: true,
    })
  }

  toggleEducators = () => {
    this.setState({
      showOptions: !this.state.showOptions,
      error: ''
    })
  }

  selectEducator = (id) => {
    this.setState({
      educatorId: id,
      showOptions: false,
      errorMessage: ''
    })
  }

  removeEducator = () => {
    this.setState({
      educatorId: ''
    })
  }

  toggleComments = () => {
    this.setState({
      comments: !this.state.comments,
    })
  }

  resetEducator = (id) => {
    this.setState({
      educatorId: id,
      errorMessage: ''
    })
  }

  assignEducator = () => {
    const { educatorId, session } = this.state;
    const { _id } = session[0];
    const token = `Bearer ${localStorage.getItem('access_token')}`;
    if (educatorId) {
      this.setState({
        makeResquest: true,
        errorMessage: ''
      })
      fetch(`${baseUrl}/a/sessions/${_id}`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        credentials: 'same-origin',
        body: JSON.stringify({
          educator_id: educatorId
        })
      })
        .then((response) => {
          this.setState({
            makeResquest: false,
          })
          if (helper.range(200, 299).indexOf(response.status) !== -1) {
            return response.json();
          }
          throw response;
        })
        .then((responseJson) => {
          this.setState({
            successMessage: responseJson.message,
            showModal: true,
          })
        })
        .catch((error) => {
          console.error(error);
          if (error) {
            error.json().then(e => {
              this.setState({
                errorMessage: e.message,
              })
            })
          }
        });
    } else {
      this.setState({
        error: 'Please select an Educator to assign'
      })
    }
  }

  closeModal = () => {
    this.setState({
      showModal: false
    }, () => {
      const { match: { params } } = this.props;
      const { id } = params;
      this.fetchSession(id);
    })
  }

  render() {
    const { session, response, showOptions, educators, educatorId, error, makeResquest, comments, showModal, errorMessage, successMessage } = this.state;
    const selectedEducator = educators.find(educator => educatorId === educator._id);
    const token = localStorage.getItem('access_token');
    return (
      <React.Fragment>
        <Header page='sessions' />
        <div id="view-session">
          <div className="top">
            <h3>View Session</h3>
            {/* <div className="select">All Profiles</div>
            <button className="button-outline-green">
                  <img src="assets/svg/plus-icon.svg" alt="" />
                  Add New
            </button> */}
          </div>
          {
            session.length === 0 && !response &&
            <div className="loader-div">
              <img src={spinner} alt="" />
            </div>
          }
          {
            response && session.map(entry => {
              const { _id, child, activity, start, end, educator, status, media, center, report } = entry;
              const { first_name, last_name } = child;
              let activity_name;
              let description;
              let catergory;
              let ageGroup;
              if (activity) {
                activity_name = activity.name;
                description = activity.description;
                catergory = activity.catergory;
                ageGroup = activity.ageGroup;
              }
              const categoryName = catergory ? catergory.name : 'n/a';
              const ageBracket = ageGroup ? `Ages ${ageGroup.min}-${ageGroup.max}` : 'n/a';
              const { timezone } = center;
              const date = moment(start, 'x').tz(timezone).format('dddd, MMMM D YYYY');
              const startTime = moment(start, 'x').tz(timezone).format('hh:mma');
              const endTime = moment(end, 'x').tz(timezone).format('hh:mma');
              const timeLeft = moment(end, 'x').diff(moment(), 'minutes');
              const duration = (end - start) / 60 / 1000 / 60;
              const unit = (duration === 1) ? `${duration} Hour` : `${duration} Hours`;
              return (
                <div className="view-session-main" key={_id}>
                  <div className="section">
                    <div className="view-card">
                      <h4>{activity ? activity_name : `${unit} Booked`}</h4>
                      <p>{activity ? description : `This session was booked for ${unit}, open to any activity.`}</p>
                      <ul>
                        <li><img src="assets/svg/icon-subject.svg" alt="" />{categoryName}</li>
                        <li><img src="assets/svg/icon-age.svg" alt="" />{ageBracket}</li>
                      </ul>
                      <div className="card-line" />
                      <div className="bottom-row">
                        <div className="session-info">
                          <h5>Duration</h5>
                          <div className="info-tag">
                            {unit}
                          </div>
                        </div>
                        <div className="session-info">
                          <h5>Session Booked</h5>
                          <div className="info-tag">
                            {startTime} - {endTime}
                          </div>
                        </div>
                        <div className="session-info">
                          <h5>Child Registered</h5>
                          <div className="info-tag">
                            <div className="name-badge">
                              CB
                            </div>
                            {first_name} {last_name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section">
                    <div className="view-card">
                      {status === 'COMPLETED' && <h4>Session Details</h4>}
                      {(status === 'NOT_STARTED' || status === 'NOT_STARTABLE') && !educator && <h4>Assign Educator</h4>}
                      {(status === 'NOT_STARTED' || status === 'NOT_STARTABLE') && educator && <h4>Booking Details</h4>}
                      {(status === 'IN_PROGRESS') && <h4>Ongoing Session Details</h4>}
                      {
                        status === 'COMPLETED' &&
                        <div className="completed-educator">
                          <h5>Educator</h5>
                          <div className="flex-div">
                            <div className="educator-name">
                              <div className='badge'>{helper.createBadge(educator.first_name, educator.last_name)}</div>
                              <p>{educator.first_name} {educator.last_name}</p>
                            </div>
                            <div className="toggle-comments" onClick={this.toggleComments}>
                              <p>{comments ? 'hide comments' : 'show comments'}</p>
                              <img className={comments ? 'spin' : ''} src={green_arrow_down} alt="" />
                            </div>
                          </div>
                          {
                            comments &&
                            <div className="comments">
                              {report}
                            </div>
                          }
                        </div>
                      }
                      {
                        status === 'IN_PROGRESS' &&
                        <div className="completed-educator">
                          <h5>Educator</h5>
                          <div className="flex-div">
                            <div className="educator-name">
                              <div className='badge'>{helper.createBadge(educator.first_name, educator.last_name)}</div>
                              <p>{educator.first_name} {educator.last_name}</p>
                            </div>
                            <div className="time-left">
                              <p>{timeLeft}</p>
                              <p>MINS LEFT</p>
                            </div>
                          </div>
                        </div>
                      }
                      {
                        (status === 'NOT_STARTED' || status === 'NOT_STARTABLE') &&
                        <label>
                          Select educator
                        <div className="select-div">
                            {
                              educatorId && selectedEducator &&
                              <div className="educator-name">
                                <div className='badge'>{helper.createBadge(selectedEducator.first_name, selectedEducator.last_name)}</div>
                                <p>{selectedEducator.first_name} {selectedEducator.last_name}</p>
                                <img src={close_button} alt="" onClick={this.removeEducator} />
                              </div>
                            }
                            <img src={arrow_down} alt="" onClick={this.toggleEducators} />
                          </div>
                          {
                            showOptions &&
                            <div className="educator-options">
                              {
                                educators.map(educator => {
                                  const { _id, first_name, last_name } = educator;
                                  return (
                                    <div className="educator" key={_id} onClick={() => this.selectEducator(_id)}>
                                      <div className="badge">{helper.createBadge(first_name, last_name)}</div>
                                      {first_name} {last_name}
                                    </div>
                                  )
                                })
                              }
                            </div>
                          }
                          <p className="error-text">{error}</p>
                        </label>
                      }
                      <div className="label">
                        <p>Selected date</p>
                        <p>{date}</p>
                      </div>
                      <div className="label">
                        <p>Selected period</p>
                        <p>{startTime} - {endTime}</p>
                      </div>
                      {
                        (status === 'NOT_STARTED' || status === 'NOT_STARTABLE') &&
                        <div className="button-div">
                          <p className="error-text">{errorMessage}</p>
                          {
                            makeResquest &&
                            <img src={spinner} alt="" />
                          }
                          {
                            educator && educatorId !== educator._id &&
                            <button className="button-outline-grey" onClick={() => this.resetEducator(educator._id)}>CANCEL</button>
                          }
                          <button className="button-orange" onClick={this.assignEducator} disabled={educator && educatorId === educator._id ? true : false}>
                            {(educator && educatorId === educator._id) || !educator ? 'ASSIGN SESSION' : 'REASSIGN SESSION'}
                          </button>
                        </div>
                      }
                      {
                        status === 'COMPLETED' && media &&
                        <div className="media-div">
                          {
                            media.map((file, index) => {
                              const { url } = file;
                              const filename = url.split('/').pop();
                              const isImage = filename.match(/.(jpg|jpeg|png|gif)$/i) && true;
                              return (
                                isImage ?
                                <div className="file" key={index}>
                                  <img src={`${url}?access_token=${token}`} alt="" />
                                </div>
                                :
                                <div className="file" key={index}>
                                  <video ref="vidRef" controls={true} width='100%' type="video/mp4">
                                    <source src={`${url}?access_token=${token}`} type="video/mp4" />
                                  </video>
                                </div>
                              )
                            })
                          }
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <Modal show={showModal} handleClose={this.closeModal}>
          <div className="popup">
            <img src={success_icon} alt="" />
            <p>{successMessage}</p>
            <button className="button-green" onClick={this.closeModal}>Ok</button>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}
