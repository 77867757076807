import React, { Component } from "react";
import moment from "moment";
import "moment-timezone";
import helper from "../helper";
import Modal from "../modal/Modal";
import dropChild from "../../assets/svg/drop-child.svg";
import SpinnerModal from "../modal/SpinnerModal";
import child_vector from "../../assets/svg/child-vector.svg";
import { baseUrl } from "../url";

class AddChild extends Component {
  state = {
    last_name: "",
    first_name: "",
    dob: "",
    gender: "",
    special_needs: "",
    school: "",
    dob_day: "",
    dob_month: "",
    dob_year: "",
    makeResquest: false,
    image: "",
    imageUrl: "",
    avatar: "",
    parent: this.props.parent,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.handleDateInput();
    const { action, child, parent } = this.props;
    if (action === "edit") {
      const { _id } = child;
      this.fetchChild(_id);
    }
  }
  fetchChild = async (id) => {
    const query = `{
      child(id:"${id}"){
        special_needs,
        school,
        dob,
        gender,
        first_name,
        last_name,
        _id,
        avatar{
          url
        }
      }
    }`;
    const data = await helper.apolloQuery(query);
    const token = await helper.verifyToken();
    const { child } = data;
    const {
      _id,
      last_name,
      first_name,
      gender,
      dob,
      school,
      special_needs,
      avatar,
    } = child;
    let formattedUrl = "";
    if (avatar) {
      const { url } = avatar;
      formattedUrl = `${url}?access_token=${token}`;
    }
    const dob_day = moment(dob, "x").format("D");
    const dob_month = moment(dob, "x").format("MMMM");
    const dob_year = moment(dob, "x").format("YYYY");
    await this.setState(
      {
        _id,
        last_name,
        first_name,
        gender,
        school,
        special_needs,
        dob_day,
        dob_month,
        dob_year,
        response: true,
        imageUrl: formattedUrl,
      },
      () => {
        this.concatDate();
      }
    );
  };
  showModal = () => {
    this.setState({ show: true });
  };
  hideModal = () => {
    this.setState({ show: false });
    window.location.reload();
  };
  handleDateInput = () => {
    let days = [];
    let years = [];

    for (let i = 1; i <= 31; i++) {
      days.push(i);
    }
    let startYear = moment().format("YYYY");
    for (let i = startYear; i >= startYear - 19; i--) {
      years.push(i);
    }

    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.setState({
      days,
      months,
      years,
    });
  };

  handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    this.setState(
      {
        [e.target.name]: e.target.value,
        [e.target.name + "Error"]: "",
      },
      () => {
        this.concatDate();
      }
    );
  };

  handleImage = (e) => {
    const file = e.target.files[0];
    this.setState({
      image: file,
      imageUrl: URL.createObjectURL(file),
    });
  };

  compressImage = async () => {
    const { image } = this.state;
    const result = await helper.compressImage(image, 0.5);
    if (result) {
      return result;
    }
    return image;
  };

  openFileDialog = (e) => {
    e.preventDefault();
    if (this.imageInput) {
      this.imageInput.click();
    }
  };

  concatDate = () => {
    const { dob_day, dob_month, dob_year } = this.state;
    if (dob_day && dob_month && dob_year) {
      let monthNo = moment().month(dob_month).format("MM");
      let date = `${dob_year}-${monthNo}-${dob_day}`;
      let dob = moment(date, "YYYY-MM_D").format("YYYY-MM-DD");
      this.setState({
        dob,
      });
    }
  };

  handleSubmit = async (e) => {
    console.log("clicked", this.state.parent);
    e.preventDefault();
    const { first_name, last_name, dob, school, special_needs, gender, image } =
      this.state;
    const errors = helper.validateChild(first_name, last_name, gender, dob);
    if (errors) {
      const { first_nameError, last_nameError, genderError, dobError } = errors;
      this.setState({
        first_nameError,
        last_nameError,
        genderError,
        dobError,
      });
    }
    console.log(errors);
    if (!errors) {
      console.log("no errors");
      const { parent } = this.props;
      console.log("THE PARENT" + parent._id);
      const { _id } = this.state;
      this.setState({
        makeResquest: true,
      });
      const uri = `${baseUrl}/a/parent/children/${parent._id}`;
      const methodType = "POST";

      const token = await `Bearer ${await helper.verifyToken()}`;

      let data;
      let contentType;

      if (image) {
        // contentType = 'multipart/form-data'
        const compressedImage = await this.compressImage();
        data = new FormData();
        data.append("first_name", first_name);
        data.append("last_name", last_name);
        data.append("dob", dob);
        data.append("school", school);
        data.append("avatar", compressedImage);

        special_needs && data.append("special_needs", special_needs);
        gender && data.append("gender", gender);
      } else {
        contentType = "application/json";
        let formattedData = {
          first_name,
          last_name,
          gender,
          dob,
        };
        if (school) formattedData = { ...formattedData, school };
        if (special_needs) formattedData = { ...formattedData, special_needs };
        data = JSON.stringify([formattedData]);
      }
      console.log(data);
      await fetch(uri, {
        method: methodType,
        headers: {
          Accept: "application/json",
          Authorization: token,
          "content-type": contentType,
        },
        body: data,
      })
        .then((response) => {
          this.setState({
            makeResquest: false,
          });
          if (helper.range(200, 299).indexOf(response.status) !== -1) {
            return response.json();
          }
          throw response;
        })
        .then((responseJson) => {
          this.showModal();
        })
        .catch((error) => {
          console.log(error);
          // error.json().then((e) => {
          //   console.log(e);
          // });
        });
    }
  };

  render() {
    console.log(this.props.parent);
    const {
      days,
      months,
      years,
      show,
      first_nameError,
      last_nameError,
      genderError,
      dobError,
      schoolError,
      special_needsError,
      makeResquest,
      last_name,
      first_name,
      gender,
      school,
      special_needs,
      dob_day,
      dob_month,
      dob_year,
      imageUrl,
    } = this.state;
    const { cancel, action } = this.props;
    let dayOption, monthOption, yearOption;
    if (days && months && years) {
      dayOption = days.map((day, index) => <option key={index}>{day}</option>);
      monthOption = months.map((month, index) => (
        <option key={index}>{month}</option>
      ));
      yearOption = years.map((year, index) => (
        <option key={index}>{year}</option>
      ));
    }
    return (
      <React.Fragment>
        <div className="add-child-modal">
          <div className="add-child">
            <form action="">
              <div className="child-avatar-vector">
                <img src={child_vector} alt="" />
              </div>
              <div className="edit-first-name">
                <label className="add-child-name">First name</label>
                <input
                  className="add-child-input-text"
                  type="text"
                  name="first_name"
                  value={first_name}
                  onChange={this.handleChange}
                />
                <p className="error-text">{first_nameError}</p>
              </div>
              <div className="edit-last-name">
                <label className="add-child-name">Last name</label>
                <input
                  className="add-child-input-text"
                  type="text"
                  name="last_name"
                  value={last_name}
                  onChange={this.handleChange}
                />
                <p className="error-text">{last_nameError}</p>
              </div>

              <div className="add-date-of-birth">
                <label>Date of Birth</label>
                <input
                  className="add-child-input-text"
                  type="text"
                  name="dob_day"
                  value={dob_day}
                  onChange={this.handleChange}
                  placeholder="01"
                />
                <select
                  name="dob_month"
                  value={dob_month}
                  onChange={this.handleChange}
                  className="add-child-input-text"
                >
                  <option
                    value=""
                    className="add-child-input-text"
                    style={{ display: "none" }}
                  >
                    Month
                  </option>
                  {monthOption}
                </select>
                <select
                  name="dob_year"
                  value={dob_year}
                  className="add-child-input-text"
                  onChange={this.handleChange}
                >
                  <option
                    className="add-child-input-text"
                    value=""
                    style={{ display: "none" }}
                  >
                    Year
                  </option>
                  {yearOption}
                </select>

                <p className="error-text">{dobError}</p>
              </div>

              <div className="add-gender">
                <label>Gender</label>
                <div className="boy-radio-position">
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    value="male"
                    onChange={this.handleChange}
                  />
                  <label>Boy</label>
                </div>
                <div className="girl-radio-position">
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value="female"
                    onChange={this.handleChange}
                  />
                  <label>Girl</label>
                </div>
              </div>

              <div className="add-school">
                <label>School name</label>
                <input
                  className="add-child-input-text"
                  type="text"
                  name="school"
                  value={school || ""}
                  onChange={this.handleChange}
                />
                <p className="error-text">{schoolError}</p>
              </div>

              <div className="add-special-needs">
                <label>Special needs</label>
                <input
                  className="add-child-input-text"
                  type="text"
                  name="special_needs"
                  value={special_needs || ""}
                  onChange={this.handleChange}
                />
                <p className="error-text">{special_needsError}</p>
              </div>
              <span
                className="add-child-button"
                type="button"
                onClick={this.handleSubmit}
              >
                <p className="edit-button-text">SAVE</p>
              </span>
            </form>
          </div>
        </div>
        {show && (
          <Modal show={show} handleClose={this.hideModal}>
            <div className="popup">
              <img src={dropChild} alt="" />
              <p className="text">Your child has been successfully added.</p>
            </div>
          </Modal>
        )}
        <SpinnerModal show={makeResquest} />
      </React.Fragment>
    );
  }
}

export default AddChild;
