import React from 'react';

const step2 = (props) => {
    const { currentStep } = props;
    if ( currentStep !== 2 ) {
        return null
    }
    return (
        <React.Fragment>
            <div>
                <h4>More about your child</h4>
                <p>Please answer the following questions to the best of your knowledge so we can support your child.</p>
            </div>
            <label>
                Does your child have any known emotional or behavioural problems? 
                <div className="radio-btns">
                    <div>
                        <input type="radio" id="yes-1" name="emotional" />
                        <label for="yes-1">
                            <img src="assets/svg/checked.svg" className="checked" alt="" />
                            Yes
                        </label>
                    </div>
                    <div>
                        <input type="radio" id="no-1" name="emotional" />
                        <label for="no-1">
                            <img src="assets/svg/checked.svg" className="checked" alt="" />
                            No
                        </label>
                    </div>
                </div>
            </label>
            <label>
                Does your child have Epilepsy?
                <div className="radio-btns">
                    <div>
                        <input type="radio" id="yes-2" name="epilepsy" />
                        <label for="yes-2">
                            <img src="assets/svg/checked.svg" className="checked" alt="" />
                            Yes
                        </label>
                    </div>
                    <div>
                        <input type="radio" id="no-2" name="epilepsy" />
                        <label for="no-2">
                            <img src="assets/svg/checked.svg" className="checked" alt="" />
                            No
                        </label>
                    </div>
                </div>
            </label>
            <label>
                Does your child have severe allergies/anaphylaxis?
                <div className="radio-btns">
                    <div>
                        <input type="radio" id="yes-3" name="allergies" />
                        <label for="yes-3">
                            <img src="assets/svg/checked.svg" className="checked" alt="" />
                            Yes
                        </label>
                    </div>
                    <div>
                        <input type="radio" id="no-3" name="allergies" />
                        <label for="no-3">
                            <img src="assets/svg/checked.svg" className="checked" alt="" />
                            No
                        </label>
                    </div>
                </div>
            </label>
            <label>
                Does your child have Asthma?
                <div className="radio-btns">
                    <div>
                        <input type="radio" id="yes-4" name="asthma" />
                        <label for="yes-4">
                            <img src="assets/svg/checked.svg" className="checked" alt="" />
                            Yes
                        </label>
                    </div>
                    <div>
                        <input type="radio" id="no-4" name="asthma" />
                        <label for="no-4">
                            <img src="assets/svg/checked.svg" className="checked" alt="" />
                            No
                        </label>
                    </div>
                </div>
            </label>
            <label>
                Please let us know if your child has any special conditions 
                <textarea></textarea>
            </label>
            {/* <div class="btn-div">
                <button type="submit">
                    
                </button>
            </div> */}
        </React.Fragment>
    )
}

export default step2;