import React, { Component } from 'react';
import moment from 'moment';
import 'moment-timezone';
import Header from '../header/header';
import Step1 from './step1';
import Step2 from './step2';

class AddChild extends Component {
    state = {
        currentStep: 1, 
    }
    componentDidMount(){
        this.handleDateInput();
    }
    handleDateInput = () => {
        let days = [];
        let years = [];

        for(let i = 1; i <= 31; i++){
            days.push(i);
        }
        let startYear = moment().format('YYYY');
        for(let i = startYear; i >= startYear-19; i--){
            years.push(i);
        }

        let months = [ 'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December' ];
        this.setState({
            days,
            months,
            years,
        })
    }
    handleChange = (e) => {
        // const { name, value } = e.target;
    }
    scrollUp = () => {      
        window.scrollTo(0, 0);
    }
    next = () => {
        this.setState({
            currentStep: 2,
        },() => {this.scrollUp()})
    }
    prev = () => {
        this.setState({
            currentStep: 1,
        },() => {this.scrollUp()})
    }
    handleSubmit = (e) => {
        e.preventDefault();
    }
    nextButton(){
        let currentStep = this.state.currentStep;
        if(currentStep === 1){
          return (
            <button onClick={this.next}>
            Next Step
            </button>        
          )
        }
        return null;
    }
    submitButton(){
        let currentStep = this.state.currentStep;
        if(currentStep === 2){
          return (
            <button onClick={this.handleSubmit}>
            Add Child
            </button>        
          )
        }
        return null;
    }
    render() {
        const { currentStep, days, months, years } = this.state;
        return(
            <React.Fragment>
                <div id="dashboard">
                    <Header/>
                </div>
                <main className="add-child-section">
                    <div className="top">
                        <h4>Add a child</h4>
                        <p>Please fill the folllowing information to add a child</p>
                        <div className="step-divs">
                            <div className={`step ${currentStep === 1? 'active': 'done'}`} onClick={this.prev}>
                                <img src="assets/svg/step-1.svg" alt="" />
                                <p>Basic Info</p>
                                <img src="assets/svg/step-check.svg"  className="check" alt="" />
                            </div>
                            <div className={`step ${currentStep === 2? 'active': ''}`}>
                                <img src="assets/svg/step-2.svg" alt="" />
                                <p>More Info</p>
                            </div>
                        </div>
                    </div>
                    <div className="form-section">
                        <div className="form-div">
                            <form onSubmit={this.handleSubmit}>
                                <Step1 currentStep={currentStep} days={days} months={months} years={years}/>
                                <Step2 currentStep={currentStep} />
                            </form>
                        </div>
                    </div>
                    {
                        currentStep === 2 &&
                        <div className="btn-div">
                            <p>By adding your child you agree to our <span>Parent Consent Agreement</span></p>
                        </div>
                    }
                    <div className="btn-div">
                        {this.nextButton()}
                        {this.submitButton()}
                    </div>
                </main>
            </React.Fragment>
        )
    }
}

export default AddChild;