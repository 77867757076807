import React, { Component } from "react";
import Header from "../header/header";
import helper from "../helper";
import { baseUrl } from "../url";
import orange_right from "../../assets/svg/orange_right_arrow.svg";
import profileImage from "../../assets/img/profile-image.png";
import spinner from "../../assets/svg/spinner.svg";
import profileAvatar from "../../assets/img/Avatar.png";
import profileAvatar2 from "../../assets/img/Avatar2.png";
import shape from "../../assets/img/Shape.png";
import deleteButtonImage from "../../assets/img/Vector.png";
import Modal from "../modal/Modal";
import success_icon from "../../assets/svg/success-icon.svg";
import SpinnerModal from "../modal/SpinnerModal";
import dropChild from "../../assets/svg/drop-child.svg";
import AddChild from "./AddChild";

class UserProfile extends Component {
  state = {
    first_name: "",
    last_name: "",
    phone_no: "",
    email: "",
    country: "",
    type: "",
    children: [],
    response: false,
    deleteResquest: false,
    deleteModal: false,
    deleteSuccess: false,
    successMessage: false,
    serverError: false,
    editProfileModal: false,
    makeResquest: false,
    show: false,
    childForm: false,
    parent: {},
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchProfile();
  }

  showModal = () => {
    this.setState({ show: true });
  };
  hideModal = () => {
    this.setState({ show: false });
    window.location.reload();
  };

  hideChildForm = () => {
    this.setState({
      childForm: false,
    });
  };

  showChildForm = () => {
    this.setState({
      childForm: true,
    });
  };

  getUserId = () => {
    const profileUrl = window.location.pathname;
    return profileUrl.split("/")[3];
  };

  fetchProfile = async () => {
    const profileId = this.getUserId();
    const query = `{
      user(id: "${profileId}"){
        _id,
        last_name,
        first_name,
        email,
        phone_no,
        type,
        country,
        address,
        ...on Parent {
          children{
            _id,
            first_name
            last_name
            dob
            gender
            previousSessions
        }
        }
      }
    }`;
    const data = await helper.apolloQuery(query);
    const {
      first_name,
      last_name,
      email,
      phone_no,
      type,
      country,
      address,
      children,
    } = data.user;

    await this.setState({
      first_name,
      last_name,
      email,
      phone_no,
      type,
      country,
      address,
      children,
      response: true,
      parent: type === "PARENT" ? data.user : {},
    });
    console.log(this.state);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { first_name, last_name, phone_no, email, country, address } =
      this.state;
    const errors = helper.validate(
      email,
      first_name,
      last_name,
      phone_no,
      country,
      address
    );
    if (errors) {
      const {
        first_nameError,
        last_nameError,
        emailError,
        phone_noError,
        countryError,
        addressError,
      } = errors;
      this.setState({
        first_nameError,
        last_nameError,
        emailError,
        phone_noError,
        countryError,
        addressError,
      });
    }
    if (!errors) {
      this.setState({
        makeResquest: true,
      });
      const token = await `Bearer ${await helper.verifyToken()}`;
      // const data = new FormData()
      // data.append('first_name', first_name)
      // data.append('last_name', last_name)
      // data.append('email', email)
      // data.append('phone_no', phone_no)
      // if(image){
      //   const compressedImage = await this.compressImage();
      //   data.append('avatar', compressedImage)
      // }

      const contentType = "application/json";
      const data = JSON.stringify({
        _id: this.getUserId(),
        first_name,
        last_name,
        email,
        phone_no,
        country,
        address,
      });

      await fetch(`${baseUrl}/a/profile`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: token,
          "content-type": contentType,
        },
        body: data,
      })
        .then((response) => {
          this.setState({
            makeResquest: false,
          });
          if (helper.range(200, 299).indexOf(response.status) !== -1) {
            return response.json();
          }

          throw response;
        })
        .then((responseJson) => {
          this.showModal();
        })
        .catch((error) => {
          error.json().then((e) => {
            console.log(e);
          });
        });
    }
  };

  showEditModal = () => {
    this.setState({
      editProfileModal: true,
    });
  };

  showDeleteModal = async () => {
    const profileId = this.getUserId();
    const query = `{
      user(id: "${profileId}"){
        _id,
        last_name,
        first_name,
        email,
        phone_no,
        type,
        country,
        address,
        ...on Parent {
          children{
            _id,
            first_name
            last_name
            dob
            gender
            previousSessions
        }
        }
      }
    }`;
    const data = await helper.apolloQuery(query);
    this.setState({
      deleteModal: true,
      userToDelete: data.user,
    });
  };

  closeDeleteModal = () => {
    const { deleteSuccess } = this.state;
    this.setState(
      {
        deleteModal: false,
        deleteResquest: false,
        serverError: "",
        successMessage: "",
      },
      () => {
        if (deleteSuccess) {
          this.setState({
            deleteSuccess: false,
          });
          return this.props.history.push("/users");
        }
      }
    );
  };

  deleteUser = () => {
    const { userToDelete } = this.state;
    const { _id, type } = userToDelete;
    const token = `Bearer ${localStorage.getItem("access_token")}`;
    this.setState({
      deleteResquest: true,
    });
    fetch(`${baseUrl}/a/user/${type.toLowerCase()}/${_id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      credentials: "same-origin",
    })
      .then((response) => {
        this.setState({
          deleteResquest: false,
        });
        if (helper.range(200, 299).indexOf(response.status) !== -1) {
          response.json();
          return this.props.history.push("/users");
        }
        throw response;
      })
      .then((responseJson) => {
        console.log(responseJson);
        this.setState({
          successMessage: responseJson.message,
          deleteSuccess: true,
        });
      })
      .catch((error) => {
        if (error) {
          return this.props.history.push("/users");
        }
      });
  };

  render() {
    const {
      first_name,
      last_name,
      email,
      phone_no,
      country,
      address,
      type,
      children,
      response,
      successMessage,
      serverError,
      deleteModal,
      userToDelete,
      deleteResquest,
      deleteSuccess,
      editProfileModal,
      first_nameError,
      last_nameError,
      phone_noError,
      countryError,
      emailError,
      addressError,
      makeResquest,
      show,
      childForm,
      parent,
    } = this.state;
    console.log(parent);
    const badge = helper.createBadge(first_name, last_name);
    let childTop = 20;
    let childNameLabelTop = 75;
    let childSessionCompletedLabelTop = 100;
    let childLineDivider = 130;

    return (
      <React.Fragment>
        <Header />
        <div id="view-profile">
          <div className="admin-profile">
            <div className="top top-content">
              <div className="users-title">
                <p>Users</p>
              </div>
              <div className="users-title-pointer">
                <img src={shape} alt=""></img>
              </div>
              <div className="view-profile-title">
                <p>View Profile</p>
              </div>
            </div>
            <div
              className="profile-details"
              style={{
                "font-family": "GT Walsheim",
                "font-style": "normal",
                "font-weight": 500,
                "font-size": "20px",
                "line-height": "28px",
                color: "#1F2023",
              }}
            >
              <div className="item">
                <div className="card">
                  <div className="tab-buttons">
                    <div>
                      <br></br>
                      <p
                        style={{
                          position: "absolute",
                          display: "flex",
                          width: "60px",
                          height: "10px",
                          left: "30px",
                        }}
                      >
                        {type.charAt(0).toUpperCase() +
                          type.substr(1).toLowerCase()}
                      </p>
                      <br></br>
                    </div>
                    <div className="tab-button active">
                      <div
                        style={{
                          display: "flex",
                          "justify-content": "center",
                          "align-items": "center",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            color: "#FFFFFF",
                            "font-size": "20px",
                            "line-height": "25px",
                          }}
                        >
                          {badge}
                        </p>
                        <img src={profileAvatar} alt="" />
                      </div>
                      <p
                        style={{
                          "text-align": "center",
                        }}
                      >
                        {first_name} {last_name}
                      </p>
                      <img src={orange_right} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="children-display">
                {children && (
                  <>
                    <div className="children-display-label">
                      <label>Kids ({children.length})</label>
                    </div>
                    <div className="children-list">
                      {children.map((child) => {
                        const top = childTop;
                        childTop += 70;
                        const labelTop = childNameLabelTop;
                        childNameLabelTop += 70;
                        const childSession = childSessionCompletedLabelTop;
                        childSessionCompletedLabelTop += 70;
                        const lineDivider = childLineDivider;
                        childLineDivider += 70;
                        return (
                          <div className="children-bubble-postion">
                            <div className="child-content">
                              <div
                                className="circle"
                                style={{
                                  // top: `${top}px`,
                                  backgroundColor: `${helper.getRandomColor()}`,
                                }}
                              >
                                <p className="circle-inner">
                                  {helper.createBadge(
                                    child.first_name,
                                    child.last_name
                                  )}
                                </p>
                              </div>
                              <div className="child-label">
                                <label>
                                  {child.first_name} {child.last_name}
                                </label>
                                <div
                                  className="child-session-completed-label"
                                  style={{ top: `${childSession}px` }}
                                >
                                  {child.previousSessions} Completed activities
                                </div>
                              </div>
                            </div>

                            <div className="child-line-divider" />
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}

                <div className="add-another-child-position">
                  <button
                    className="button-orange-wider"
                    type="button"
                    id="dropdownMenu2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={this.showChildForm}
                  >
                    <img src="assets/svg/plus-icon.svg" alt="" />
                    Add Another Child
                  </button>
                </div>
              </div>
              {!response && (
                <div className="loader-div">
                  <img src={spinner} alt="" />
                </div>
              )}
              {!childForm && response && !editProfileModal && (
                <div className="users-view-profile">
                  <div className="profile-information">
                    <div className="users-view-profile-delete">
                      <span
                        className="users-view-profile-delete-button"
                        type="button"
                        onClick={() => this.showDeleteModal()}
                      >
                        <img src={deleteButtonImage} alt=""></img>
                      </span>
                      <p>DELETE PROFILE</p>
                    </div>
                    <Modal
                      show={deleteModal}
                      handleClose={this.closeDeleteModal}
                    >
                      {userToDelete && !deleteSuccess && (
                        <div className="delete-modal">
                          <h5>Delete {userToDelete.type}</h5>
                          <p>
                            Are you sure you want to delete{" "}
                            {userToDelete.first_name} {userToDelete.last_name}?
                          </p>
                          <div className="btn-div">
                            <button
                              className="outline"
                              onClick={this.closeDeleteModal}
                            >
                              NO
                            </button>
                            <button onClick={this.deleteUser}>YES</button>
                          </div>
                          {deleteResquest && (
                            <img src={spinner} alt="" className="loader" />
                          )}
                          {!deleteResquest &&
                          serverError &&
                          this.toType(serverError) === this.toType([]) ? (
                            serverError.map((item) => {
                              const { message } = item;
                              return (
                                <span className="error-text">{message}</span>
                              );
                            })
                          ) : (
                            <span className="error-text">
                              {serverError && serverError.message}
                            </span>
                          )}
                        </div>
                      )}
                      {deleteSuccess && (
                        <div className="delete-modal">
                          <img
                            src={success_icon}
                            alt=""
                            className="success_icon"
                          />
                          <p>{successMessage}</p>
                          <div className="btn-div">
                            <button onClick={this.closeDeleteModal}>Ok</button>
                          </div>
                        </div>
                      )}
                    </Modal>
                    <div className="users-view-profile-edit">
                      <div className="users-view-profile-edit-text">
                        <span
                          type="button"
                          onClick={() => this.showEditModal()}
                        >
                          EDIT DETAILS
                        </span>
                      </div>
                    </div>
                    <div className="name-display">
                      <div
                        style={{
                          display: "flex",
                          "justify-content": "center",
                          "align-items": "center",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            color: "#FFFFFF",
                            "font-size": "20px",
                            "line-height": "25px",
                            left: "60px",
                            top: "48px",
                          }}
                        >
                          {badge}
                        </p>
                        <img src={profileAvatar2} alt="" />
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          left: "120px",
                          top: "35px",
                        }}
                        className="name-location"
                      >
                        <p>
                          {first_name} {last_name}
                        </p>
                        <p>
                          {address}, {country}
                        </p>
                      </div>
                    </div>
                    <div className="label">
                      <p>Full Name</p>
                      <div className="users-view-form-text">
                        <p>
                          <b>
                            {first_name} {last_name}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="label">
                      <p>Email Address</p>
                      <div className="users-view-form-text">
                        <p>
                          <b>{email}</b>
                        </p>
                      </div>
                    </div>
                    <div className="label">
                      <p>Phone Number</p>
                      <div className="users-view-form-text">
                        <p>
                          <b>{phone_no}</b>
                        </p>
                      </div>
                    </div>
                    <div className="label">
                      <p>Country of Origin</p>
                      <div className="users-view-form-text">
                        <p>
                          <b>{country}</b>
                        </p>
                      </div>
                    </div>
                    {address && (
                      <div className="label">
                        <p>Address</p>
                        <div className="users-view-form-text">
                          <p>
                            <b>{address}</b>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!childForm && response && editProfileModal && (
                <div className="edit-modal">
                  <form action="">
                    <div className="view-profile-image">
                      <div className="view-profile-icon">
                        <img src={profileImage} alt="" />
                      </div>
                    </div>
                    <div className="edit-first-name">
                      <label className="edit-first-name-label">
                        First name
                      </label>
                      <input
                        className="edit-first-name-input"
                        type="text"
                        name="first_name"
                        value={first_name}
                        onChange={this.handleChange}
                      />
                      <p className="error-text">{first_nameError}</p>
                    </div>
                    <div className="edit-last-name">
                      <label className="edit-last-name-label">Last name</label>
                      <input
                        className="edit-last-name-input"
                        type="text"
                        name="last_name"
                        value={last_name}
                        onChange={this.handleChange}
                      />
                      <p className="error-text">{last_nameError}</p>
                    </div>
                    <div className="edit-phone-number">
                      <label className="edit-phone-number-label">
                        Phone number
                      </label>
                      <input
                        className="edit-phone-number-input"
                        type="text"
                        name="phone_no"
                        value={phone_no}
                        onChange={this.handleChange}
                      />
                      <p className="error-text">{phone_noError}</p>
                    </div>
                    <div className="edit-country">
                      <label className="edit-country-label">
                        Country of origin
                      </label>
                      <select
                        className="edit-country-select"
                        type="text"
                        name="country"
                        value={country}
                        onChange={this.handleChange}
                      >
                        <option value="Uganda">Uganda</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Rwanda">Rwanda</option>
                      </select>
                      <p className="error-text">{countryError}</p>
                    </div>
                    <div className="edit-email">
                      <label className="edit-email-label">Email</label>
                      <input
                        className="edit-email-input"
                        type="text"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                      />
                      <p className="error-text">{emailError}</p>
                    </div>
                    <div className="edit-address">
                      <label className="edit-address-label">
                        Residential Address
                      </label>
                      <input
                        className="edit-address-input"
                        type="text"
                        name="address"
                        value={address}
                        onChange={this.handleChange}
                      />
                      <p className="error-text">{addressError}</p>
                    </div>
                    <span
                      className="edit-button"
                      type="button"
                      onClick={this.handleSubmit}
                    >
                      <p className="edit-button-text">SAVE</p>
                    </span>
                  </form>
                  <SpinnerModal show={makeResquest} />
                </div>
              )}

              {childForm && (
                <AddChild hide={this.hideChildForm} parent={parent} />
              )}
            </div>
          </div>
        </div>
        {show && (
          <Modal show={show} handleClose={this.hideModal}>
            <div className="popup">
              <img src={dropChild} alt="" />
              <p className="text">Profile updated successfully.</p>
            </div>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default UserProfile;
