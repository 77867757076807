import React from 'react';

export default function Steps(props) {
  const { step, handleStep } = props;
  return (
    <div className="steps">
      <div className={`step ${step > 1 ? 'previous' : step === 1 ? 'active' : ''}`}  onClick={(e) => handleStep(e, 1)}>
        <div className="number-div">
          {step > 1 ? <span>&#10003;</span> : '1'}
        </div>
        Parent Information
          </div>
      <div className={`step ${step > 2 ? 'previous' : step === 2 ? 'active' : ''}`}>
        <div className="number-div">
          {step > 2 ? <span>&#10003;</span> : '2'}
        </div>
        Child Information
          </div>
    </div>
  )
}
