import React from 'react';

export default function FormSteps(props) {
  const {currentStep, sessionType} = props;
  return (
    <div>
      <div className="steps">
          <div className={`step ${currentStep > 1? 'previous':currentStep === 1? 'active': ''}`}>
            <div className="number-div">
              { currentStep > 1? <span>&#10003;</span> : '1' }
            </div>
            Child Information
          </div>
          <div className={`step ${currentStep > 2? 'previous':currentStep === 2? 'active': ''}`}>
            <div className="number-div">
              { currentStep > 2? <span>&#10003;</span> : '2' }
            </div>
            {sessionType==='activity'?"Available Activity":"Time & Date"}
          </div>
          <div className={`step ${currentStep > 3? 'previous':currentStep === 3? 'active': ''}`}>
            <div className="number-div">
              { currentStep > 3? <span>&#10003;</span> : '3' }
            </div>
            {sessionType==='activity'?"Time & Date":"Educator"}
          </div>
          {
            sessionType==='activity' &&
            <div className={`step ${currentStep > 4? 'previous':currentStep === 4? 'active': ''}`}>
              <div className="number-div">
                { currentStep > 4? <span>&#10003;</span> : '4' }
              </div>
              Educator
            </div>
          }
        </div>
    </div>
  )
}
