import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
// import Landing from './components/landing-page';
import Login from "./components/authentication/login";
import CreateAccount from "./components/authentication/create-account";
import Dashboard from "./components/dashboard/dashboard";
import AddChild from "./components/add-child/mainForm";
import Users from "./components/users/Users";
import Activities from "./components/activities/Activities";
import Sessions from "./components/sessions/Sessions";
import ViewSession from "./components/sessions/ViewSession";
import AdminProfile from "./components/admin-profile/AdminProfile";
import CreateSession from "./components/sessions/CreateSessions";
import UserProfile from "./components/users/UserProfile";
import { ProtectedRoute } from "./components/authentication/Authentication";
import "./App.css";
import CacheBuster from "./CacheBuster";

class App extends Component {
  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload, version }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
            localStorage.setItem("appVersion", version);
          }

          return (
            <div className="App">
              <Router>
                <Switch>
                  {/* <Route exact path="/" component={Landing}/> */}
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/dashboard" />}
                  />
                  <Route path="/login" component={Login} />
                  <ProtectedRoute path="/dashboard" component={Dashboard} />
                  <ProtectedRoute
                    path="/create-account"
                    component={CreateAccount}
                  />
                  <ProtectedRoute path="/add-child" component={AddChild} />
                  <ProtectedRoute exact path="/users" component={Users} />
                  <ProtectedRoute
                    exact
                    path="/users/:type/:id"
                    component={UserProfile}
                  />
                  <ProtectedRoute path="/activities" component={Activities} />
                  <ProtectedRoute
                    exact
                    path="/sessions/:type"
                    component={Sessions}
                  />
                  <ProtectedRoute
                    exact
                    path="/sessions/view/:id"
                    component={ViewSession}
                  />
                  <ProtectedRoute
                    path="/admin-profile"
                    component={AdminProfile}
                  />
                  <ProtectedRoute
                    exact
                    path="/session/create"
                    component={CreateSession}
                  />
                </Switch>
              </Router>
            </div>
          );
        }}
      </CacheBuster>
    );
  }
}

export default App;
