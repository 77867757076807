import React, { Component } from "react";
import moment from "moment";
import "moment-timezone";
import Header from "../header/header";
import helper from "../helper";
import spinner from "../../assets/svg/spinner.svg";

export default class Sessions extends Component {
  state = {
    sessions: [],
    totalSessions: 0,
    sessionsType: "in_progress",
    duration: "All Durations",
    period: "Today",
    periodStart: moment().startOf("day").format("YYYY-MM-DD HH:mm"),
    periodEnd: moment().endOf("day").format("YYYY-MM-DD HH:mm"),
    response: false,
    search: "",
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const { type } = params;
    let value;
    if (type === "ongoing") {
      value = "in_progress";
    }
    if (type === "booked") {
      value = "not_started";
    }
    if (type === "completed") {
      value = "completed";
    }
    this.setState(
      {
        sessionsType: value,
      },
      () => {
        this.fetchSessions();
      }
    );
  }

  fetchSessions = async () => {
    this.setState({
      response: false,
      sessions: [],
    });
    const { sessionsType, periodStart, periodEnd, search } = this.state;

    let qs = [];
    if (search && search !== "") qs[qs.length] = `search: "${search}"`;
    if (sessionsType) qs[qs.length] = `status: ${sessionsType.toUpperCase()}`;
    if (periodStart && periodEnd && (!search || search === ""))
      qs[
        qs.length
      ] = `sessionDate: {start: "${periodStart}", end: "${periodEnd}"}`;
    const query = `{
      sessions(${qs.join(",")}){
        _id,
        created_at,
        child{
          first_name,
          last_name,
          previousSessions,
          avatar{
            url
          }
        },
        activity{
          name,
          description,
          ageGroup{
            min
          },
        },
        center{
          name,
          address,
          timezone,
        },
        educator{
          first_name,
          last_name,
        }
        dropTime,
        pickupTime,
        start,
        end,
        status
      }
    }`;
    const data = await helper.apolloQuery(query);
    const { sessions } = data;
    this.setState({
      sessions,
      totalSessions: (sessions && sessions.length) || 0,
      response: true,
    });
  };

  handleSessionsType = (value) => {
    this.setState(
      {
        sessionsType: value,
      },
      () => {
        this.fetchSessions();
        let type;
        if (value === "in_progress") {
          type = "ongoing";
        }
        if (value === "not_started") {
          type = "booked";
        }
        if (value === "completed") {
          type = "completed";
        }
        this.props.history.push({
          pathname: `/sessions/${type}`,
        });
      }
    );
  };

  handleDuration = (value) => {
    this.setState({
      duration: value,
    });
  };

  handlePeriod = (value) => {
    let start,
      end = "";
    const format = "YYYY-MM-DD HH:mm";

    switch (value) {
      case "Today":
        start = moment().startOf("day").format(format);
        end = moment().endOf("day").format(format);
        break;
      case "This week":
        start = moment().startOf("week").format(format);
        end = moment().endOf("week").format(format);
        break;
      case "This month":
        start = moment().startOf("month").format(format);
        end = moment().endOf("month").format(format);
        break;
      default:
        start = "";
        end = "";
        break;
    }
    this.setState(
      {
        period: value,
        periodStart: start,
        periodEnd: end,
      },
      () => {
        this.fetchSessions();
      }
    );
  };

  viewSession = (id) => {
    this.props.history.push({
      pathname: `/sessions/view/${id}`,
    });
  };

  createSession = () => {
    this.props.history.push({
      pathname: `/session/create`,
    });
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        [e.target.name + "Error"]: "",
      },
      () => {
        this.fetchSessions();
      }
    );
  };

  render() {
    const {
      sessionsType,
      // duration,
      period,
      sessions,
      totalSessions,
      response,
      search,
    } = this.state;
    const sessionTypes = {
      in_progress: "Ongoing sessions",
      not_started: "Booked sessions",
      completed: "Completed sessions",
    };
    return (
      <React.Fragment>
        <Header page="sessions" />
        <div className="sessions-page">
          <div className="top">
            <h3>Sessions</h3>
            <div className="filter">
              <div className="labels">
                <span>Search</span>
                <div className="search">
                  <input
                    type="text"
                    name="search"
                    value={search}
                    placeholder="Search child/educator..."
                    onChange={this.handleChange}
                  ></input>
                </div>
              </div>
              <div className="labels">
                <span>Showing sessions</span>
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenu2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {sessionTypes[sessionsType]}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenu2"
                  >
                    <button
                      className="dropdown-item"
                      onClick={() => this.handleSessionsType("in_progress")}
                    >
                      Ongoing sessions
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => this.handleSessionsType("not_started")}
                    >
                      Booked sessions
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => this.handleSessionsType("completed")}
                    >
                      Completed sessions
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="labels">
                <span>Duration</span>
                <div className="dropdown">
                  <button className="btn  dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {duration}
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    <button className="dropdown-item" onClick={() => this.handleDuration('All duration')}>All duration</button>
                    <button className="dropdown-item" onClick={() => this.handleDuration('1 Hour')}>1 Hour</button>
                    <button className="dropdown-item" onClick={() => this.handleDuration('2 Hours')}>2 Hours</button>
                    <button className="dropdown-item" onClick={() => this.handleDuration('3 Hours')}>3 Hours</button>
                    <button className="dropdown-item" onClick={() => this.handleDuration('4 Hours')}>4 Hours</button>
                  </div>
                </div>
              </div> */}
              <div className="labels">
                <span>Period</span>
                <div className="dropdown">
                  <button
                    className="btn  dropdown-toggle"
                    type="button"
                    id="dropdownMenu2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {period}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenu2"
                  >
                    <button
                      className="dropdown-item"
                      onClick={() => this.handlePeriod("All")}
                    >
                      All
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => this.handlePeriod("Today")}
                    >
                      Today
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => this.handlePeriod("This week")}
                    >
                      This week
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => this.handlePeriod("This month")}
                    >
                      This month
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sessions-content">
            <div
              className={
                sessionsType === "Completed sessions"
                  ? "table-lists completed"
                  : "table-lists"
              }
            >
              <div className="table-top">
                <h5>
                  {sessionTypes[sessionsType]} ({totalSessions})
                </h5>
                <button className="button-green" onClick={this.createSession}>
                  Book Session
                </button>
              </div>
              {sessions && sessions.length > 0 && response && (
                <div className="body">
                  <table>
                    <tbody>
                      <tr>
                        <th>ACTIVITY</th>
                        <th>DATE BOOKED</th>
                        <th>DURATION</th>
                        <th>DATE</th>
                        <th>TIME</th>
                        <th>CHILD</th>
                        <th>EDUCATOR</th>
                        <th></th>
                      </tr>
                      {sessions.map((session) => {
                        const {
                          _id,
                          activity,
                          child,
                          start,
                          end,
                          educator,
                          center,
                          created_at,
                        } = session;
                        const { first_name, last_name } = child;
                        const first = educator ? educator.first_name : "";
                        const last = educator ? educator.last_name : "";
                        const educator_name =
                          first && last ? `${first} ${last}` : "unassigned";
                        let activity_name;
                        if (activity) {
                          activity_name = activity.name;
                        }
                        const { timezone } = center;
                        const dateBooked = created_at
                          ? moment(created_at)
                              .tz(timezone)
                              .format("DD MMM YYYY hh:mma")
                          : created_at;
                        const date = moment(start, "x")
                          .tz(timezone)
                          .format("DD MMM YYYY");
                        const startTime = moment(start, "x")
                          .tz(timezone)
                          .format("hh:mma");
                        const endTime = moment(end, "x")
                          .tz(timezone)
                          .format("hh:mma");
                        const duration = (end - start) / 60 / 1000 / 60;
                        const unit =
                          duration === 1
                            ? `${duration} Hour`
                            : `${duration} Hours`;
                        return (
                          <tr key={_id}>
                            <td>
                              {activity ? activity_name : `${unit} Booked`}
                            </td>
                            <td>{dateBooked ? dateBooked : ""}</td>
                            <td>{unit}</td>
                            <td>{date}</td>
                            <td>
                              {startTime} - {endTime}
                            </td>
                            <td>
                              {first_name} {last_name}
                            </td>
                            <td>{educator_name}</td>
                            <td>
                              <span
                                className="view"
                                onClick={() => this.viewSession(_id)}
                              >
                                View Details
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="pagination-div">
                    <div className="pagination">
                      <button>Prev</button>
                      <div>1</div>
                      <button>Next</button>
                    </div>
                  </div>
                </div>
              )}
              {response && sessions.length === 0 && (
                <div className="session-empty-state">
                  <p>
                    There are no{" "}
                    <b>{sessionTypes[sessionsType].toLowerCase()}</b>{" "}
                    {period === "All" ? "" : period.toLowerCase()}
                  </p>
                </div>
              )}
              {!response && (
                <div className="loader-div">
                  <img src={spinner} alt="" />
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
