import React, { Component } from "react";
import Header from "../header/header";
import helper from "../helper";
import spinner from "../../assets/svg/spinner.svg";

export default class Activities extends Component {
  state = {
    activities: [],
    totalActvities: 0,
    response: false,
  };
  componentDidMount() {
    this.fetchActivities();
  }
  fetchActivities = async () => {
    const query = `{
      activities{
        _id,
        name,
        description,
        duration{
          time,
          unit
        },
        category{
          name
        },
        ageGroup{
          min,
          max
        },
      }
    }`;
    const data = await helper.apolloQuery(query);
    const { activities } = data;
    await this.setState({
      activities,
      totalActvities: activities.length,
      response: true,
    });
  };
  render() {
    const { activities, response, totalActvities } = this.state;
    return (
      <React.Fragment>
        <Header page="activities" />
        <div className="activities-summary">
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-xs-12 col-md-3">
                <div className="number-act">
                  <p>{totalActvities} Activities</p>
                </div>
              </div>
              <div className="col-xs-12 col-md-5">
                <div className="filter">
                  <div className="labels">
                    <span>Showing activities</span>
                    <div className="dropdown">
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        For all ages
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <button className="dropdown-item" type="button">
                          4-7
                        </button>
                        <button className="dropdown-item" type="button">
                          8-12
                        </button>
                        <button className="dropdown-item" type="button">
                          13-17
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="labels">
                    <span>Category</span>
                    <div className="dropdown">
                      <button
                        className="btn  dropdown-toggle"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        All categories
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <button className="dropdown-item" type="button">
                          Science
                        </button>
                        <button className="dropdown-item" type="button">
                          Technology
                        </button>
                        <button className="dropdown-item" type="button">
                          Engineering
                        </button>
                        <button className="dropdown-item" type="button">
                          Math
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="labels">
                    <span>Showing activities</span>
                    <div className="dropdown">
                      <button
                        className="btn  dropdown-toggle"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        For all ages
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <button className="dropdown-item" type="button">
                          4-7
                        </button>
                        <button className="dropdown-item" type="button">
                          8-12
                        </button>
                        <button className="dropdown-item" type="button">
                          13-17
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="activities-catalogue" className="p60">
          <div className="container-fluid main-content">
            <div className="activities-wrap">
              {activities.length > 0 &&
                response &&
                activities.map((activity) => {
                  const {
                    _id,
                    name,
                    description,
                    duration,
                    category,
                    ageGroup,
                  } = activity;
                  const { time } = duration;
                  const categoryName = category ? category.name : "n/a";
                  const ageGroupMax =
                    ageGroup && ageGroup.max ? `-${ageGroup.max}` : "+";
                  const ageBracket = ageGroup
                    ? `Ages ${ageGroup.min}${ageGroupMax}`
                    : "n/a";
                  const activityDuration =
                    time === 1 ? `${time} hour` : `${time} hours`;
                  return (
                    <div className="act-card" key={_id}>
                      <div className="act-card-details">
                        <h5>{name}</h5>
                        <p>
                          Duration <i className="fas fa-circle" />
                          {activityDuration}{" "}
                        </p>
                        <p>{description}</p>
                        <div className="card-line" />
                        <ul>
                          <li>
                            <img src="assets/svg/icon-subject.svg" alt="" />
                            {categoryName}
                          </li>
                          <li>
                            <img src="assets/svg/icon-age.svg" alt="" />
                            {ageBracket}
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              {/* <div className="act-card">
                <a href>
                <div className="act-image">
                <img src="assets/img/group-toys.jpeg" alt="" />
                </div>
                <div className="act-card-details">
                  <h5>Robotics with Legos</h5>
                  <p>Duration <i className="fas fa-circle" />1 hour </p>
                  <p>The mouse wheels or touchscreens make scrolling faster and easier than clicking.</p>
                  <div className="card-line" />
                  <ul>
                    <li><img src="assets/svg/icon-subject.svg" alt="" />Physics</li>
                    <li><img src="assets/svg/icon-age.svg" alt="" />Ages 5 and up</li>
                  </ul>
                </div>
                </a>
              </div> */}
            </div>
            {!response && (
              <div className="loader-div">
                <img src={spinner} alt="" />
              </div>
            )}
            {/* <div className="load-more">
              <button className="button-outline-green">
                Load More
              </button>
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
