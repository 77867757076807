import React from 'react';

const step1 = (props) => {
    const { currentStep, days, months, years } = props;
    let dayOption,
        monthOption,
        yearOption;
    if (days && months && years) {
        dayOption = days.map((day, index) => 
            <option key={index}>{day}</option>
        )
        monthOption = months.map((month, index) => 
            <option key={index}>{month}</option>
        )
        yearOption = years.map((year, index) => 
            <option key={index}>{year}</option>
        )
    }

    if ( currentStep !== 1 ) {
        return null
    }
    return (
        <React.Fragment>
            <label>
                Child’s Full Name
                <input/>
            </label>
            <label>
                Date of Birth
                <div className="d-flex dob">
                    <select name="dob_day">
                        <option>Day</option>
                        {dayOption}
                    </select>
                    <select name="dob_month">
                        <option>Month</option>
                        {monthOption}
                    </select>
                    <select name="dob_year">
                        <option>Year</option>
                        {yearOption}
                    </select>
                </div>
            </label>
            <label>
                Address
                <input/>
            </label>
            <div>
                <h4>Contact Persons</h4>
                <p>Please add a contact to ring in case of an energency and state relationship to your child</p>
            </div>
            <label>
                Contact Name
                <input/>
            </label>
            <label>
                Relationship to Child
                <input/>
            </label>
            <label>
                Phone Number
                <input/>
            </label>
        </React.Fragment>
    )
}

export default step1;