import React, { Component } from "react";
import Header from "../header/header";
import helper from "../helper";
import user_icon from "../../assets/svg/user-icon.svg";
import orange_right from "../../assets/svg/orange_right_arrow.svg";
import logout_icon from "../../assets/svg/logout-icon.svg";
import spinner from "../../assets/svg/spinner.svg";

export default class AdminProfile extends Component {
  state = {
    user: {},
    response: false,
  };

  componentDidMount() {
    this.fetchProfile();
  }

  fetchProfile = async () => {
    const query = `{
      user{
        _id,
        last_name,
        first_name,
        email,
        phone_no,
        address,
        type,
        ...on AdminType{
          center{
              _id
              name
              country
          }
      },
      ...on EducatorType{
        center{
            _id
            name
            country
        }
    }
      }
    }`;
    const data = await helper.apolloQuery(query);
    const { user } = data;
    await this.setState({
      user,
      response: true,
    });
  };

  logout = () => {
    helper.logout();
  };
  render() {
    const { user, response } = this.state;
    const first_name = user && user.first_name;
    const last_name = user && user.last_name;
    const email = user && user.email;
    const phone_no = user && user.phone_no;
    const address = user && user.address;
    console.log(user);
    const center = user && user.center;
    return (
      <React.Fragment>
        <Header />
        <div className="admin-profile">
          <div className="top">
            <h3>Manage Account</h3>
          </div>
          <div className="profile-details">
            <div className="tab-buttons">
              <div className="tab-button active">
                <img src={user_icon} alt="" />
                <p>My Details</p>
                <img src={orange_right} alt="" />
              </div>
              <div className="tab-button" onClick={this.logout}>
                <img src={logout_icon} alt="" />
                <p>Logout</p>
              </div>
            </div>
            {!response && (
              <div className="loader-div">
                <img src={spinner} alt="" />
              </div>
            )}
            {response && user && (
              <div className="profile-information">
                <div className="name-display">
                  <div className="name-location">
                    <p>
                      {first_name} {last_name}
                    </p>
                    <p>
                      {center ? center.name : "Lagos"},{" "}
                      {center ? center.country : "Nigeria"}
                    </p>
                  </div>
                </div>
                <div className="label">
                  <p>Full Name</p>
                  <p>
                    {first_name} {last_name}
                  </p>
                </div>
                <div className="label">
                  <p>Email Address</p>
                  <p>{email}</p>
                </div>
                <div className="label">
                  <p>Phone Number</p>
                  <p>{phone_no}</p>
                </div>
                {address && (
                  <div className="label">
                    <p>Address</p>
                    <p>{address}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
