import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import helper from '../helper';
import stemcafe_logo from '../../assets/svg/stemcafe-logo.svg';
import mobile_logo from '../../assets/svg/mobile-logo.svg';

let admin_name = '';

class Header extends Component {
  state = {
    user: null
  }
  componentDidMount(){
    this.fetchUser();
  }
  fetchUser = async () => {
    const query = `{
      user{
        last_name,
        first_name,
      }
    }`
    const data = await helper.apolloQuery(query);
    const { user } = data;
    await this.setState({
      user
    }, () =>{
      admin_name = `${user.first_name} ${user.last_name}`
    })
  }
  logout = () => {
    helper.logout();
  }
  render() {
    const { page } = this.props;
    const { user } = this.state;
    const first_name = user && (user.first_name || '');
    const last_name = user && (user.last_name || '');
    return (
      <header>
        <div>
          <nav className="navbar navbar-light navbar-expand-lg">
            <span className="navbar-brand desktop">
              <img src={stemcafe_logo} alt="STEMCafe.io logo" />
            </span>
            <span className="navbar-brand mobile">
              <img src={mobile_logo} alt="STEMCafe.io logo" />
            </span>
            <div className="justify-content-end d-flex">
              <ul className="navbar-nav mobile">
                <li className="nav-item dropdown">
                  <div className="avi-text">
                    {/* <img src="https://randomuser.me/api/portraits/men/91.jpg" alt="" /> */}
                    <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink-4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div className="name">
                        <p>MANAGE ACCOUNT</p>
                        <h5>{admin_name || `${first_name} ${last_name}`}</h5>
                      </div>
                    </span>
                    <div className="dropdown-menu dropdown-menu-right dropdown-info" aria-labelledby="navbarDropdownMenuLink-4">
                      <Link to='/admin-profile' className="dropdown-item">Settings &amp; Profile</Link>
                      <span className="dropdown-item" onClick={this.logout}>Logout</span>
                    </div>
                  </div>
                </li>
              </ul>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
              </button>
            </div>
            <div className="collapse navbar-collapse justify-content-center" id="navbarNavDropdown">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link to="/dashboard" className={page === 'home' ? 'nav-link active' : 'nav-link'}>Home <span className="sr-only">(current)</span></Link>
                </li>
                <li className="nav-item">
                  <Link to="/sessions/ongoing" className={page === 'sessions' ? 'nav-link active' : 'nav-link'}>Sessions</Link>
                </li>
                <li className="nav-item">
                  <Link to="/activities" className={page === 'activities' ? 'nav-link active' : 'nav-link'}>Activities</Link>
                </li>
                <li className="nav-item">
                  <Link to="/users" className={page === 'users' ? 'nav-link active' : 'nav-link'}>Users</Link>
                </li>
              </ul>
            </div>
            <ul className="navbar-nav desktop">
              <li className="nav-item dropdown">
                <div className="avi-text">
                  {/* <img src="https://randomuser.me/api/portraits/men/91.jpg" alt="" /> */}
                  <span to="" className="nav-link dropdown-toggle" id="navbarDropdownMenuLink-4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div className="name">
                      <p>MANAGE ACCOUNT</p>
                      <h5>{admin_name || `${first_name} ${last_name}`}</h5>
                    </div>
                  </span>
                  <div className="dropdown-menu dropdown-menu-right dropdown-info" aria-labelledby="navbarDropdownMenuLink-4">
                    <Link to='/admin-profile' className="dropdown-item">Settings &amp; Profile</Link>
                    <span className="dropdown-item" onClick={this.logout}>Logout</span>
                  </div>
                </div>
              </li>
              {/* <li className="nav-item no-mobile">
                                <div className="cart">
                                    <img src="assets/svg/cart.svg" alt="" />
                                </div>
                            </li> */}
            </ul>
          </nav>
        </div>
      </header>

    )
  }
}

export default Header;