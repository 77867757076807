import React, { Component } from 'react';
import moment from 'moment';
import Steps from './Steps';
import spinner from '../../assets/svg/spinner.svg';
import IntlTelInput from 'react-intl-tel-input';

import 'react-intl-tel-input/dist/main.css';

export default class CreateEducator extends Component {
  state = {
    step: 1
  }
  componentDidMount() {
    this.handleDateInput();
  }
  handleStep = (e, step) => {
    e.preventDefault();
    const { validate } = this.props;
    const noError = validate();
    if (noError) {
      this.setState({
        step
      })
    }
  }
  handleDateInput = () => {
    let days = [];
    let years = [];

    for (let i = 1; i <= 31; i++) {
      days.push(i);
    }
    let startYear = moment().format('YYYY');
    for (let i = startYear; i >= startYear - 19; i--) {
      years.push(i);
    }

    let months = ['January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'];
    this.setState({
      days,
      months,
      years,
    })
  }

  toType = (obj) => {
    return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
  }

  render() {
    const { step, days, months, years } = this.state;
    const {
      first_nameError,
      last_nameError,
      emailError,
      countryError,
      addressError,
      phone_noError,
      serverError,
      makeResquest,
      handleChange,
      handleChildren,
      createProfile,
      children,
      addChild,
      removeChild,
      parent,
      handlePhoneNo,
      phone_no
    } = this.props;

    const { first_name, last_name, email, country, address } = parent;

    let dayOption,
      monthOption,
      yearOption;
    if (days && months && years) {
      dayOption = days.map((day, index) =>
        <option key={index}>{day}</option>
      )
      monthOption = months.map((month, index) =>
        <option key={index}>{month}</option>
      )
      yearOption = years.map((year, index) =>
        <option key={index}>{year}</option>
      )
    }
    return (
      <React.Fragment>
        <div className="create-profile">
          <Steps step={step} handleStep={this.handleStep} />
          <form>
            {
              step === 1 &&
              <React.Fragment>
                <div className="form-row">
                  <label>
                    First name
                      <input type="text" name="first_name" onChange={handleChange} value={first_name} />
                    <p className="error-text">{first_nameError}</p>
                  </label>
                  <label>
                    Last name
                      <input type="text" name="last_name" onChange={handleChange} value={last_name} />
                    <p className="error-text">{last_nameError}</p>
                  </label>
                </div>
                <label>
                  Email Address
                    <input type="text" name="email" onChange={handleChange} value={email} />
                  <p className="error-text">{emailError}</p>
                </label>
                <label>
                  Phone number
                  <IntlTelInput
                    preferredCountries={['ng', 'rw', 'ke', 'ug']}
                    separateDialCode={true}
                    useMobileFullscreenDropdown={false}
                    formatOnInit={false}
                    onPhoneNumberChange={handlePhoneNo}
                    value={phone_no}
                  />
                  <p className="error-text">{phone_noError}</p>
                </label>
                <label>
                  Address
                  <input type="text" name="address" onChange={handleChange} value={address} />
                  <p className="error-text">{addressError}</p>
                </label>
                <label>
                  Country
                  <input type="text" name="country" onChange={handleChange} value={country} />
                  <p className="error-text">{countryError}</p>
                </label>
                <div className="btn-div">
                  <button className="button-green" onClick={(e) => this.handleStep(e, 2)}>NEXT</button>
                </div>
              </React.Fragment>
            }
            {
              step === 2 &&
              <React.Fragment>
                {
                  children && children.length - 1 > 0 &&
                  <div className="children">
                    <h5>Children ({children.length - 1})</h5>
                    <div className="children-row">
                      {
                        children.slice(0, -1).map((child, index) => {
                          const { first_name, last_name } = child;
                          return (
                            <div className="child" key={index}>
                              <div className="badge">GB</div>
                              <p>{first_name} {last_name}</p>
                              <span onClick={removeChild(index)}>Remove</span>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                }
                {
                  children.map((child, idx) => {
                    const { first_name, last_name, gender, dob_day, dob_month, dob_year } = child;
                    const proceed = first_name && last_name && gender && dob_day && dob_month && dob_year ? true : false;
                    if (idx === children.length - 1) {
                      return (
                        <React.Fragment key={idx}>
                          <div className="form-row">
                            <label>
                              First name
                          <input type="text" name="first_name" onChange={handleChildren(idx)} />
                              <p className="error-text">{first_nameError}</p>
                            </label>
                            <label>
                              Last name
                          <input type="text" name="last_name" onChange={handleChildren(idx)} />
                              <p className="error-text">{last_nameError}</p>
                            </label>
                          </div>
                          <label className="select-label">
                            Date of Birth
                      <div className="select-div">
                              <select name="dob_day" onChange={handleChildren(idx)}>
                                <option value="" style={{ display: 'none' }}>Day</option>
                                {dayOption}
                              </select>
                              <select name="dob_month" onChange={handleChildren(idx)}>
                                <option value="" style={{ display: 'none' }}>Month</option>
                                {monthOption}
                              </select>
                              <select name="dob_year" onChange={handleChildren(idx)}>
                                <option value="" style={{ display: 'none' }}>Year</option>
                                {yearOption}
                              </select>
                            </div>
                            <p className="error-text">{}</p>
                          </label>
                          <div className="gender">
                            Gender
                      <div className="select-gender">
                              <label className="container">Boy
                          <input type="radio" name="gender" value="male" onChange={handleChildren(idx)} />
                                <span className="checkmark"></span>
                              </label>
                              <label className="container">Girl
                          <input type="radio" name="gender" value="female" onChange={handleChildren(idx)} />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <p className="error-text">{emailError}</p>
                          </div>
                          <label>
                            School name
                      <input type="text" name="school" onChange={handleChildren(idx)} />
                            <p className="error-text">{addressError}</p>
                          </label>
                          <label>
                            Special needs
                      <input type="text" name="special_needs" onChange={handleChildren(idx)} />
                            <p className="error-text">{addressError}</p>
                          </label>
                          <div className="btn-div">
                            <button className="button-outline-green" onClick={addChild} disabled={!proceed}>ADD CHILD</button>
                          </div>
                        </React.Fragment>
                      )
                    }
                    return null;
                  })
                }
                <div className="btn-div row">
                  {
                    makeResquest &&
                    <img src={spinner} alt="" />
                  }
                  {
                    serverError && this.toType(serverError) === this.toType([]) ?
                      serverError.map(item => {
                        const { message } = item;
                        return (
                          <p className="error-text">{message}</p>
                        )
                      })
                      :
                      <p className="error-text">{serverError && serverError.message}</p>
                  }
                  <button className="button-green" onClick={createProfile}>SAVE &amp; COMPLETE</button>
                </div>
              </React.Fragment>
            }
          </form>
        </div>
      </React.Fragment>
    )
  }
}
