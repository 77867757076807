import React, { Component } from "react";
import Header from "../header/header";
import CreateEducator from "./CreateEducator";
import CreateParent from "./CreateParent";
import helper from "../helper";
import spinner from "../../assets/svg/spinner.svg";
import success_icon from "../../assets/svg/success-icon.svg";
import Modal from "../modal/Modal";
import moment from "moment";

import { baseUrl } from "../url";

export default class Users extends Component {
  state = {
    users: [],
    response: false,
    makeResquest: false,
    deleteResquest: false,
    userType: "All Profiles",
    type: "parent",
    showForm: false,
    success: false,
    first_name: "",
    last_name: "",
    phone_no: "",
    phoneInputValue: "",
    email: "",
    country: "",
    country_code: "+234",
    address: "",
    totalUsers: 0,
    deleteModal: false,
    deleteSuccess: false,
    children: [
      {
        first_name: "",
        last_name: "",
        gender: "",
        dob_day: "",
        dob_month: "",
        dob_year: "",
        special_needs: "",
        school: "",
      },
    ],
  };
  componentDidMount() {
    this.fetchUsers();
  }
  fetchUsers = async () => {
    const { userType } = this.state;
    const qs = `type: ${userType.toUpperCase()}`;
    const query = `{
      users${userType !== "All Profiles" ? `(${qs})` : ""}{
        collection{
          _id,
          first_name,
          last_name,
          phone_no,
          created_at,
          type,
        }
      }
    }`;
    const data = await helper.apolloQuery(query);
    const { users } = data;
    let { collection } = users;
    collection = collection.filter((user) => user);
    this.setState({
      users: collection,
      totalUsers: (collection && collection.length) || 0,
      response: true,
    });
  };

  handleUserType = (value) => {
    this.setState(
      {
        user: [],
        response: false,
        userType: value,
      },
      () => {
        this.fetchUsers();
      }
    );
  };

  showForm = (type) => {
    this.setState({
      type,
      showForm: true,
    });
  };

  closeForm = () => {
    const { success } = this.state;
    this.setState(
      {
        type: "",
        showForm: false,
        success: false,
        first_nameError: "",
        last_nameError: "",
        phone_noError: "",
        addressError: "",
        emailError: "",
        countryError: "",
      },
      () => {
        if (success) {
          this.fetchUsers();
        }
      }
    );
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
      serverError: "",
    });
  };

  handlePhoneNumber = (isValid, value, countryData, number) => {
    const { name } = countryData;
    const phone_no = number.replace(/\s/g, "");
    this.setState({
      phone_no,
      country: name,
      phone_noError: "",
      phoneInputValue: value,
    });
  };

  handlePhoneNumber = (isValid, value, countryData, number) => {
    const { name } = countryData;
    const phone_no = number.replace(/\s/g, "");
    this.setState({
      phone_no,
      country: name,
      phone_noError: "",
      phoneInputValue: value,
    });
  };

  handlePhoneNumber = (isValid, value, countryData, number) => {
    const { name } = countryData;
    const phone_no = number.replace(/\s/g, "");
    this.setState({
      phone_no,
      country: name,
      phone_noError: "",
      phoneInputValue: value,
    });
  };

  handlePhoneNumber = (isValid, value, countryData, number) => {
    const { name } = countryData;
    const phone_no = number.replace(/\s/g, "");
    this.setState({
      phone_no,
      country: name,
      phone_noError: "",
      phoneInputValue: value,
    });
  };

  // concatChildDob = (idx) => {
  //   const {children} = this.state;
  //   children.forEach((child, sidx) => {
  //     const { dob_day, dob_month, dob_year } = child;
  //     if (dob_day && dob_month && dob_year) {
  //       let monthNo = moment().month(dob_month).format("MM");
  //       let date = `${dob_year}-${monthNo}-${dob_day}`
  //       let dob = moment(date, 'YYYY-MM_D').format('YYYY-MM-DD');
  //       console.log(dob)
  //       if (idx !== sidx) return child;
  //       return { ...child, dob }
  //     }
  //   })
  // }

  handleChildren = (idx) => (e) => {
    const children = this.state.children.map((child, sidx) => {
      if (idx !== sidx) return child;
      return { ...child, [e.target.name]: e.target.value };
    });

    this.setState({ children });
  };

  handleAddChild = (e) => {
    e.preventDefault();
    this.setState({
      children: this.state.children.concat([
        {
          first_name: "",
          last_name: "",
          gender: "",
          dob_day: "",
          dob_month: "",
          dob_year: "",
          special_needs: "",
          school: "",
        },
      ]),
    });
  };

  handleRemoveChild = (idx) => () => {
    this.setState({
      children: this.state.children.filter((s, sidx) => idx !== sidx),
    });
  };

  validate = () => {
    const {
      first_name,
      last_name,
      phone_no: unformattedPhone,
      email,
      country,
      address,
      type,
    } = this.state;
    const first_nameError = !first_name ? "Please enter a first name" : "";
    const last_nameError = !last_name ? "Please enter a last name" : "";
    const phone_noError = !unformattedPhone
      ? "Please enter a valid phone number"
      : "";
    const emailError = !email ? "Please enter a valid email address" : "";
    const countryError = !country ? "Please select a country" : "";
    const addressError = !address ? "Please enter a valid address" : "";

    const noError =
      type === "parent"
        ? !first_nameError &&
          !last_nameError &&
          !phone_noError &&
          !emailError &&
          !countryError &&
          !addressError
        : !first_nameError && !last_nameError && !phone_noError && !emailError;

    this.setState({
      first_nameError,
      last_nameError,
      phone_noError,
      emailError,
      countryError,
      addressError,
    });

    return noError;
  };

  createProfile = (e) => {
    e.preventDefault();
    const {
      first_name,
      last_name,
      phone_no,
      email,
      country,
      address,
      type,
      children,
    } = this.state;
    const noError = this.validate();
    if (noError) {
      this.setState({
        makeResquest: true,
        serverError: "",
      });
      const token = `Bearer ${localStorage.getItem("access_token")}`;
      let formattedChildren;
      if (children) {
        formattedChildren = children.slice(0, -1).map((child, idx) => {
          const {
            first_name,
            last_name,
            gender,
            dob_day,
            dob_month,
            dob_year,
            school,
            special_needs,
          } = child;
          let monthNo = moment().month(dob_month).format("MM");
          let date = `${dob_year}-${monthNo}-${dob_day}`;
          let childData = {
            first_name,
            last_name,
            gender,
            dob: moment(date, "YYYY-MM_D").format("YYYY-MM-DD"),
          };
          if (school) childData = { ...childData, school };
          if (special_needs) childData = { ...childData, special_needs };
          return childData;
        });
      }
      const data =
        type === "parent"
          ? {
              type,
              first_name,
              last_name,
              phone_no,
              email,
              country,
              address,
              children: formattedChildren,
            }
          : {
              type,
              first_name,
              last_name,
              phone_no,
              email,
            };
      fetch(`${baseUrl}/a/user`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
        credentials: "same-origin",
        body: JSON.stringify(data),
      })
        .then((response) => {
          this.setState({
            makeResquest: false,
          });
          if (helper.range(200, 299).indexOf(response.status) !== -1) {
            return response.json();
          }
          throw response;
        })
        .then((responseJson) => {
          console.log(responseJson);
          this.setState({
            successMessage: responseJson.message,
            success: true,
          });
        })
        .catch((error) => {
          if (error) {
            error.json().then((e) => {
              console.log(e);
              this.setState({
                serverError: e,
              });
            });
          }
        });
    }
  };

  showDeleteModal = (user) => {
    this.setState({
      deleteModal: true,
      userToDelete: user,
    });
  };

  closeDeleteModal = () => {
    const { deleteSuccess } = this.state;
    this.setState(
      {
        deleteModal: false,
        deleteResquest: false,
        serverError: "",
        successMessage: "",
      },
      () => {
        if (deleteSuccess) {
          this.fetchUsers();
          this.setState({
            deleteSuccess: false,
          });
        }
      }
    );
  };

  deleteUser = () => {
    const { userToDelete } = this.state;
    const { _id, type } = userToDelete;
    const token = `Bearer ${localStorage.getItem("access_token")}`;
    this.setState({
      deleteResquest: true,
    });
    fetch(`${baseUrl}/a/user/${type.toLowerCase()}/${_id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      credentials: "same-origin",
    })
      .then((response) => {
        this.setState({
          deleteResquest: false,
        });
        if (helper.range(200, 299).indexOf(response.status) !== -1) {
          return response.json();
        }
        throw response;
      })
      .then((responseJson) => {
        console.log(responseJson);
        this.setState({
          successMessage: responseJson.message,
          deleteSuccess: true,
        });
      })
      .catch((error) => {
        if (error) {
          error.json().then((e) => {
            console.log(e);
            this.setState({
              serverError: e,
            });
          });
        }
      });
  };

  toType = (obj) => {
    return {}.toString
      .call(obj)
      .match(/\s([a-zA-Z]+)/)[1]
      .toLowerCase();
  };

  viewUser = (type, id) => {
    console.log(type, id);
    this.props.history.push({
      pathname: `/users/${type.toLowerCase()}/${id}`,
    });
  };

  render() {
    const {
      users,
      userType,
      response,
      showForm,
      type,
      success,
      first_nameError,
      last_nameError,
      phone_noError,
      emailError,
      countryError,
      addressError,
      successMessage,
      serverError,
      makeResquest,
      totalUsers,
      children,
      first_name,
      last_name,
      email,
      country,
      phone_no,
      address,
      phoneInputValue,
      deleteModal,
      userToDelete,
      deleteResquest,
      deleteSuccess,
    } = this.state;
    const parent = {
      first_name,
      last_name,
      email,
      country,
      phone_no,
      address,
    };
    return (
      <React.Fragment>
        <Header page="users" />
        <div id="users-page">
          <div className="top">
            <h3>{totalUsers} Users</h3>
            <div className="filter">
              <div className="labels">
                <span>User category</span>
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenu2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {userType}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenu2"
                  >
                    <button
                      className="dropdown-item"
                      onClick={() => this.handleUserType("All Profiles")}
                    >
                      All Profiles
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => this.handleUserType("Parent")}
                    >
                      Parent
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => this.handleUserType("Educator")}
                    >
                      Educator
                    </button>
                  </div>
                </div>
              </div>
              <div className="labels">
                <button
                  className="button-outline-green"
                  type="button"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img src="assets/svg/plus-icon.svg" alt="" />
                  Add New
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <button
                    className="dropdown-item"
                    onClick={() => this.showForm("parent")}
                  >
                    Parent
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => this.showForm("educator")}
                  >
                    Educator
                  </button>
                </div>
              </div>
            </div>
            {/* <button className="button-outline-green">
                  <img src="assets/svg/plus-icon.svg" alt="" />
                  Add New
            </button> */}
          </div>
          <div className="users-container">
            <div className="table-lists">
              {/* <div className="top">
                <h5>Booked Sessions (10)</h5>
                <button>Explore activities</button>
              </div> */}
              {!response && (
                <div className="loader-div">
                  <img src={spinner} alt="" />
                </div>
              )}
              {response && (
                <div className="body">
                  <table>
                    <tbody>
                      <tr>
                        <th>FIRST NAME</th>
                        <th>LAST NAME</th>
                        <th>CONTACT</th>
                        <th>DATE ADDED</th>
                        <th>PROFILE</th>
                        <th></th>
                        <th></th>
                      </tr>
                      {users &&
                        users.map((user) => {
                          const {
                            _id,
                            first_name,
                            last_name,
                            phone_no,
                            created_at,
                            type,
                          } = user;
                          return (
                            <tr key={_id}>
                              <td>{first_name}</td>
                              <td>{last_name}</td>
                              <td>{phone_no}</td>
                              <td>
                                {moment(created_at).format("DD MMMM YYYY")}
                              </td>
                              <td>
                                <span
                                  className={
                                    type === "PARENT"
                                      ? "user-tag parent"
                                      : "user-tag educator"
                                  }
                                >
                                  {type}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="view"
                                  onClick={() => this.viewUser(type, _id)}
                                >
                                  View Details
                                </span>
                              </td>
                              <td>
                                <button
                                  className="delete-button"
                                  onClick={() => this.showDeleteModal(user)}
                                >
                                  DELETE
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="pagination-div">
                    <div className="pagination">
                      <button>Prev</button>
                      <div>1</div>
                      <button>Next</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Modal show={deleteModal} handleClose={this.closeDeleteModal}>
            {userToDelete && !deleteSuccess && (
              <div className="delete-modal">
                <h5>Delete {userToDelete.type}</h5>
                <p>
                  Are you sure you want to delete {userToDelete.first_name}{" "}
                  {userToDelete.last_name}?
                </p>
                <div className="btn-div">
                  <button className="outline" onClick={this.closeDeleteModal}>
                    NO
                  </button>
                  <button onClick={this.deleteUser}>YES</button>
                </div>
                {deleteResquest && (
                  <img src={spinner} alt="" className="loader" />
                )}
                {!deleteResquest &&
                serverError &&
                this.toType(serverError) === this.toType([]) ? (
                  serverError.map((item) => {
                    const { message } = item;
                    return <span className="error-text">{message}</span>;
                  })
                ) : (
                  <span className="error-text">
                    {serverError && serverError.message}
                  </span>
                )}
              </div>
            )}
            {deleteSuccess && (
              <div className="delete-modal">
                <img src={success_icon} alt="" className="success_icon" />
                <p>{successMessage}</p>
                <div className="btn-div">
                  <button onClick={this.closeDeleteModal}>Ok</button>
                </div>
              </div>
            )}
          </Modal>
        </div>
        <Modal show={showForm} handleClose={this.closeForm}>
          {!success && showForm && type === "educator" && (
            <CreateEducator
              first_nameError={first_nameError}
              last_nameError={last_nameError}
              phone_noError={phone_noError}
              emailError={emailError}
              serverError={serverError}
              makeResquest={makeResquest}
              handleChange={this.handleChange}
              createProfile={this.createProfile}
              closeForm={this.closeForm}
              handlePhoneNo={this.handlePhoneNumber}
            />
          )}
          {!success && showForm && type === "parent" && (
            <CreateParent
              first_nameError={first_nameError}
              last_nameError={last_nameError}
              phone_noError={phone_noError}
              emailError={emailError}
              countryError={countryError}
              addressError={addressError}
              serverError={serverError}
              makeResquest={makeResquest}
              handleChange={this.handleChange}
              createProfile={this.createProfile}
              closeForm={this.closeForm}
              validate={this.validate}
              handleChildren={this.handleChildren}
              children={children}
              addChild={this.handleAddChild}
              removeChild={this.handleRemoveChild}
              parent={parent}
              phone_no={phoneInputValue}
              handlePhoneNo={this.handlePhoneNumber}
            />
          )}
          {success && (
            <div className="popup">
              <img src={success_icon} alt="" />
              <p>{successMessage}</p>
              <button className="button-green" onClick={this.closeForm}>
                Ok
              </button>
            </div>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}
