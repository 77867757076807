import React, { Component } from 'react';
import Header from '../header/header';
import Forms from './Forms';
import orange_right from '../../assets/svg/orange_right_arrow.svg';
import activity_icon from '../../assets/svg/activity.svg';
import duration_icon from '../../assets/svg/duration.svg';
import activity_icon_active from '../../assets/svg/activity-active.svg';
import duration_icon_active from '../../assets/svg/duration-active.svg';

export default class CreateSessions extends Component {
  state = {
    currentTab: 'duration',
    currentStep: 1,
  }
  handleTabs = (currentTab) => {
    this.setState({
      currentTab,
      currentStep: 1
    })
  }

  handleStep = (step) => {
    this.setState({
      currentStep: step
    })
  }
  render() {
    const { currentTab, currentStep } = this.state;
    return (
      <React.Fragment>
        <Header page='sessions' />
        <div className="create-session-page">
          <div className="top">
            <p>Sessions</p>
            <p>&#x25B8;</p>
            <p>book session</p>
          </div>
          <div className="create-session-content">
            <div className="tab-buttons">
              <div className="tab-buttons">
                <div className={currentTab === 'duration' ? 'tab-button active' : 'tab-button'} onClick={() => this.handleTabs('duration')}>
                  <img src={currentTab === 'duration' ? duration_icon_active : duration_icon} alt="" />
                  <p>Book by Duration</p>
                  {currentTab === 'duration' && <img src={orange_right} alt="" />}
                </div>
                <div className={currentTab === 'activity' ? 'tab-button active' : 'tab-button'} onClick={() => this.handleTabs('activity')}>
                  <img src={currentTab === 'activity' ? activity_icon_active : activity_icon} alt="" />
                  <p>Book by Activity</p>
                  {currentTab === 'activity' && <img src={orange_right} alt="" />}
                </div>
              </div>
            </div>
            <div className="create-session-forms">
              <Forms sessionType={currentTab} currentStep={currentStep} handleStep={this.handleStep}/>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
