import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import rocket from "../../assets/svg/rocket-illustrative.svg";
import spinner from "../../assets/svg/spinner.svg";
import helper from "../helper";
import { baseUrl } from "../url";
import moment from "moment";
import "moment-timezone";

class Login extends Component {
  state = {
    email: "",
    password: "",
    makeResquest: false,
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
      serverError: "",
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    const emailError = email ? "" : "Please enter a valid email address";
    const passwordError = password ? "" : "Please enter your password";
    if (!email || !password) {
      this.setState({
        emailError,
        passwordError,
      });
    }
    if (email && password) {
      this.setState({
        makeResquest: true,
      });
      fetch(`${baseUrl}/a/auth/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        body: JSON.stringify({
          email,
          password,
        }),
      })
        .then((response) => {
          this.setState({
            makeResquest: false,
          });
          if (helper.range(200, 299).indexOf(response.status) !== -1) {
            return response.json();
          }
          throw response;
        })
        .then((responseJson) => {
          const { token } = responseJson;
          const date = moment();
          localStorage.setItem("access_token", token);
          localStorage.setItem("token_time", date);
          this.loggedIn();
        })
        .catch((error) => {
          console.error(error);
          if (error) {
            error.json().then((e) => {
              this.setState({
                serverError: e.message,
              });
            });
          }
        });
    }
  };

  loggedIn = () => {
    const { from } = this.props.location.state || {
      from: { pathname: "/dashboard" },
    };
    // return <Redirect to={from}/>
    this.props.history.push({
      pathname: from.pathname,
    });
  };
  render() {
    const { emailError, passwordError, makeResquest, serverError } = this.state;
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const token = localStorage.getItem("access_token");
    if (token) {
      return <Redirect to={from} />;
    }
    return (
      <React.Fragment>
        <div className="navigation">
          <div className="logo">
            <img src="assets/svg/stemcafe-logo-white.svg" alt="" />
          </div>
        </div>
        <div className="login-container">
          <div className="side-bar acesss">
            <div className="rocket">
              <img src={rocket} alt="" />
            </div>
          </div>

          <div className="signin-form access">
            <h2>Sign In</h2>
            <div className="login-form">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label htmlFor="">Email address</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Type your email here.."
                    onChange={this.handleChange}
                  />
                  <p className="error-text">{emailError}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="">Password</label>
                  <input
                    type="password"
                    name="password"
                    placeholder="Type your password here.."
                    onChange={this.handleChange}
                  />
                  <p className="error-text">{passwordError}</p>
                </div>
                {/* <span>forgot password?</span> */}
                <p className="error-text">{serverError}</p>
                <div className="button-div">
                  <button className="button-orange" type="submit">
                    sign in
                  </button>
                </div>
              </form>
              {makeResquest && (
                <div className="loader-div">
                  <img src={spinner} alt="" />
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Login);
