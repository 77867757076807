import React, { Component } from 'react';

class CreateAccount extends Component {
    render() {
        return (
            <React.Fragment>
                <div>
                    <div className="navigation">
                        <div className="logo">
                            <img src="assets/svg/stemcafe-logo-white.svg" alt="" />
                        </div>
                    </div>
                    <div className="create-account-container">
                        <div className="side-bar acesss">
                            <div className="rocket" />
                        </div>
                        <div className="signup-form access">
                            <h2>Create Account</h2>
                            <p>Setup your account to book a session &amp; get your kids to create amazing stuff</p>
                            <ul className="nav justify-content-center mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Your info</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Select plan</a>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div className="create-account">
                                        <form action>
                                            <div className="form-group">
                                                <label htmlFor>Full name</label>
                                                <input type="text" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor>Email address</label>
                                                <input type="email" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor>Phone number</label>
                                                <input type="number" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor>Password</label>
                                                <input type="password" />
                                            </div>
                                            <a href>Next step</a>
                                        </form>
                                        <span>By signing up your agree to our <a href>Terms of use</a> and <a href>Privacy policy</a></span>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    <div className="select-plan">
                                        <div className="plan">
                                            <div className="plan-details">
                                                <ul>
                                                    <li>Start</li>
                                                    <li className="cost">Free</li>
                                                    <li className="toggle">
                                                        <input type="radio" name="radio" id="plan-1" defaultChecked="checked" />
                                                        <label htmlFor="plan-1">Select plan</label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="plan-benefits">
                                                <ul id="tick">
                                                    <li>Access to all activities</li>
                                                    <li>Walk-in fee of N1,500/hour</li>
                                                    <li>No membership fee required</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="plan">
                                            <div className="plan-details">
                                                <ul>
                                                    <li>Individual (1 Child)</li>
                                                    <li className="cost">₦5000<span>/year</span></li>
                                                    <li className="toggle">
                                                        <input type="radio" name="radio" id="plan-2" defaultChecked="checked" />
                                                        <label htmlFor="plan-2">Select plan</label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="plan-benefits">
                                                <ul id="tick">
                                                    <li>Access to all activities</li>
                                                    <li>10% discount on all activities</li>
                                                    <li>Kid gets to test new games before the general release date</li>
                                                    <li>Access to information on international STEM competitions and enrolment support</li>
                                                    <li>Special projects designed for celebrants to play and learn with their friends, will include Virtual reality content. Celebrants can also bring up to 2 friends for a free 1-hour session.</li>
                                                    <li>Access to branded Stem Cafe items </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="plan">
                                            <div className="plan-details">
                                                <ul>
                                                    <li>Individual (Up to 4 kids)</li>
                                                    <li className="cost">₦12,500<span>/year</span></li>
                                                    <li className="toggle">
                                                        <input type="radio" name="radio" id="plan-3" defaultChecked="checked" />
                                                        <label htmlFor="plan-3">Select plan</label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="plan-benefits">
                                                <ul id="tick">
                                                    <li>Access to all activities</li>
                                                    <li>10% discount on all activities</li>
                                                    <li>Kid gets to test new games before the general release date</li>
                                                    <li>Access to information on international STEM competitions and enrolment support</li>
                                                    <li>Special projects designed for celebrants to play and learn with their friends, will include Virtual reality content. Celebrants can also bring up to 2 friends for a free 1-hour session.</li>
                                                    <li>Access to branded Stem Cafe items</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <span>By signing up your agree to our <a href>Terms of use</a> and <a href>Privacy policy</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CreateAccount;