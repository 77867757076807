import React from 'react';
import spinner from '../../assets/svg/spinner.svg';

class Modal extends React.Component{
    render(){
        const showHideClassname = this.props.show ? "spinner-modal display-block" : "spinner-modal display-none";
        return(
            <div className={showHideClassname}>
                <img src={spinner} alt='Loading' width='70'/>
            </div>
        )
    }
}

export default Modal;