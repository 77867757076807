import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "moment-timezone";
import Header from "../header/header";
import heroBg from "../../assets/img/dashboard-hero.png";
import spinner from "../../assets/svg/spinner.svg";
import helper from "../helper";

class Dashboard extends Component {
  state = {
    sessions: [],
    completedSessions: 0,
    bookedSessions: 0,
    activities: 0,
    response: false,
  };

  componentDidMount() {
    this.fetchSessions();
    this.fetchCompletedSessions();
    this.fetchTotalActivities();
  }

  fetchSessions = async () => {
    const start = moment().startOf("day").format("YYYY-MM-DD HH:mm");
    const end = moment().endOf("day").format("YYYY-MM-DD HH:mm");
    const query = `{
      sessions(status:NOT_STARTED, sessionDate: {start: "${start}", end: "${end}"}){
        _id,
        child{
          first_name,
          last_name,
          previousSessions,
          avatar{
            url
          }
        },
        activity{
          name,
          description,
          ageGroup{
            min
          }, 
        },
        center{
          name,
          address,
          timezone
        },
        educator{
          first_name,
          last_name,
        }
        dropTime,
        pickupTime,
        start,
        end,
      }
    }`;
    const data = await helper.apolloQuery(query);
    const { sessions } = data;
    this.setState({
      sessions,
      bookedSessions: (sessions && sessions.length) || 0,
      response: true,
    });
  };

  fetchCompletedSessions = async () => {
    const query = `{
      sessions(status:COMPLETED){
        _id,
      }
    }`;
    const data = await helper.apolloQuery(query);
    const { sessions } = data;
    this.setState({
      completedSessions: (sessions && sessions.length) || 0,
    });
  };

  fetchTotalActivities = async () => {
    const query = `{
      activities{
        _id,
      }
    }`;
    const data = await helper.apolloQuery(query);
    const { activities } = data;
    this.setState({
      activities: (activities && activities.length) || 0,
    });
  };

  navigate = () => {
    this.props.history.push({
      pathname: "/sessions/ongoing",
    });
  };

  viewSession = (id) => {
    this.props.history.push({
      pathname: `/sessions/view/${id}`,
    });
  };

  render() {
    const {
      sessions,
      completedSessions,
      bookedSessions,
      activities,
      response,
    } = this.state;
    return (
      <React.Fragment>
        <Header page="home" />
        <div id="dashboard">
          {!response && (
            <div className="loader-div">
              <img src={spinner} alt="" />
            </div>
          )}
          {response && (
            <main className="dashboard-main">
              <div
                className="dashboard-hero"
                style={{ backgroundImage: `url(${heroBg})` }}
              >
                <img src="assets/svg/tri-1.svg" className="img-1" alt="" />
                <img src="assets/svg/tri-2.svg" className="img-2" alt="" />
                <img src="assets/svg/tri-3.svg" className="img-3" alt="" />
                <img src="assets/svg/tri-4.svg" className="img-4" alt="" />
                <img src="assets/svg/male.svg" className="male" alt="" />
                <img src="assets/svg/female.svg" className="female" alt="" />
                <div className="text-div">
                  <h5>Welcome!</h5>
                  <p>Explore today’s fun and amazing activities lined up.</p>
                  <button
                    className="button-outline-green"
                    onClick={this.navigate}
                  >
                    View Today’s sessions
                  </button>
                </div>
              </div>
              <div className="line" />
              <div className="cards">
                {/* <div className="left">
                                <div className="add-child card">
                                    <h6>Your Kids (3)</h6>
                                    <div className="child">
                                        <div className="content">
                                            <div className="name-badge">JB</div>
                                            <div className="name">
                                                <h6>Jeremiah Boyle</h6>
                                                <p>20 Completed activities</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="child">
                                        <div className="content">
                                            <div className="name-badge">JB</div>
                                            <div className="name">
                                                <h6>Jeremiah Boyle</h6>
                                                <p>20 Completed activities</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="child">
                                        <div className="content active">
                                            <div className="name-badge">JB</div>
                                            <div className="name">
                                                <h6>Jeremiah Boyle</h6>
                                                <p>20 Completed activities</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-div">
                                        <button>
                                            <img src="assets/svg/icon-plus-yellow.svg" alt="" />
                                            Add another child
                                        </button>
                                    </div>
                                </div>
                            </div> */}
                {/* <div className="right"> */}
                <div className="item">
                  <div className="card">
                    <div>
                      <h6>Total Activities</h6>
                      <h5>{activities}</h5>
                    </div>
                    <img src="assets/svg/top-activities.svg" alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    <div>
                      <h6>Completed Sessions</h6>
                      <h5>{completedSessions}</h5>
                    </div>
                    <img src="assets/svg/completed-activities.svg" alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="card">
                    <div>
                      <h6>Booked Sessions</h6>
                      <h5>{bookedSessions}</h5>
                    </div>
                    <img src="assets/svg/upcoming-activities.svg" alt="" />
                  </div>
                </div>
              </div>
              {/* </div> */}
              <div className="upcoming-activities">
                <div className="table-top">
                  <h5>Booked Sessions Today ({bookedSessions})</h5>
                </div>
                <div className="body">
                  {sessions.length > 0 && (
                    <table>
                      <tbody>
                        <tr>
                          <th>ACTIVITY</th>
                          <th>DURATION</th>
                          <th>DATE</th>
                          <th>TIME</th>
                          <th>CHILD</th>
                          <th>EDUCATOR</th>
                          <th></th>
                        </tr>
                        {sessions.slice(0, 10).map((session) => {
                          const {
                            _id,
                            activity,
                            child,
                            start,
                            end,
                            educator,
                            center,
                          } = session;
                          const { first_name, last_name } = child;
                          const first = educator ? educator.first_name : "";
                          const last = educator ? educator.last_name : "";
                          const educator_name =
                            first && last ? `${first} ${last}` : "unassigned";
                          let activity_name;
                          if (activity) {
                            activity_name = activity.name;
                          }
                          const { timezone } = center;
                          const date = moment(start, "x")
                            .tz(timezone)
                            .format("DD MMM YYYY");
                          const startTime = moment(start, "x")
                            .tz(timezone)
                            .format("hh:mma");
                          const endTime = moment(end, "x")
                            .tz(timezone)
                            .format("hh:mma");
                          const duration = (end - start) / 60 / 1000 / 60;
                          const unit =
                            duration === 1
                              ? `${duration} Hour`
                              : `${duration} Hours`;
                          return (
                            <tr key={_id}>
                              <td>
                                {activity ? activity_name : `${unit} Booked`}
                              </td>
                              <td>{unit}</td>
                              <td>{date}</td>
                              <td>
                                {startTime} - {endTime}
                              </td>
                              <td>
                                {first_name} {last_name}
                              </td>
                              <td>{educator_name}</td>
                              <td>
                                <span
                                  className="view"
                                  onClick={() => this.viewSession(_id)}
                                >
                                  View Details
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                  {response && sessions.length === 0 && (
                    <div className="session-empty-state">
                      <p>
                        There are no <b>booked sessions</b> today
                      </p>
                    </div>
                  )}
                  <div className="pagination-div">
                    <div className="loader-div">
                      <button className="button-green" onClick={this.navigate}>
                        View all sessions
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="upcoming-activities completed">
              <div className="table-top">
                <h5>Completed activities (1)</h5>
                <button>Explore activities</button>
              </div>
              <div className="body">
                <table>
                  <tbody>
                    <tr>
                      <th>ACTIVITY</th>
                      <th>DURATION</th>
                      <th>DATE</th>
                      <th>TIME</th>
                      <th>CHILD REGISTERED</th>
                      <th></th>
                    </tr>
                    <tr>
                      <td>Build a self driving car</td>
                      <td>1 Hour</td>
                      <td>10 April 2019</td>
                      <td>11:00am - 1:00pm</td>
                      <td>
                        <div className="name-badge">
                          <div>
                            JB
                          </div>
                        </div>
                        <div className="name-badge">
                          <div>
                            PB
                          </div>
                        </div>
                      </td>
                      <td><span className="view">View Details</span></td>
                    </tr>
                    <tr>
                      <td>Build a self driving car</td>
                      <td>1 Hour</td>
                      <td>10 April 2019</td>
                      <td>11:00am - 1:00pm</td>
                      <td>
                        <div className="name-badge">
                          <div>
                            JB
                          </div>
                        </div>
                        <div className="name-badge">
                          <div>
                            PB
                          </div>
                        </div>
                      </td>
                      <td><span className="view">View Details</span></td>
                    </tr>
                    <tr>
                      <td>Build a self driving car</td>
                      <td>1 Hour</td>
                      <td>10 April 2019</td>
                      <td>11:00am - 1:00pm</td>
                      <td>
                        <div className="name-badge">
                          <div>
                            JB
                          </div>
                        </div>
                        <div className="name-badge">
                          <div>
                            PB
                          </div>
                        </div>
                      </td>
                      <td><span className="view">View Details</span></td>
                    </tr>
                  </tbody>
                </table>
                <div className="pagination-div">
                  <div className="pagination">
                    <button>Prev</button>
                    <div>1</div>
                    <button>Next</button>
                  </div>
                </div>
              </div>
            </div> */}
            </main>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Dashboard);
