import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import DatePicker from 'react-datepicker';
import helper from '../helper';
import FormSteps from './FormSteps';
import close_button from '../../assets/svg/small-close-button.svg';
import age_icon from '../../assets/svg/icon-age.svg'
import subject_icon from '../../assets/svg/icon-subject.svg';
import spinner from '../../assets/svg/spinner.svg';
import { baseUrl } from '../url';
import Modal from '../modal/Modal';
import success_icon from '../../assets/svg/success-icon.svg';

import "react-datepicker/dist/react-datepicker.css";

class Forms extends Component {
  state = {
    // currentStep: 1,
    parent: null,
    children: [],
    childId: null,
    child: null,
    duration: 0,
    educator: null,
    searchResults: [],
    userType: '',
    time: '',
    activities: [],
    selectedActivity: {},
  }
  componentDidMount() {
    const year = moment().format('YYYY');
    const month = moment().format('MMMM');
    const day = moment().format('D');
    this.setState({
      day,
      month,
      year
    }, () => {
      this.formatDate();
    })
    this.handleDateInput();
    this.fetchActivities();
  }
  handleDateInput = () => {
    let days = [];
    let years = [];

    for (let i = 1; i <= 31; i++) {
      days.push(i);
    }
    let startYear = moment().format('YYYY');
    for (let i = startYear; i >= startYear - 19; i--) {
      years.push(i);
    }

    let months = ['January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'];
    this.setState({
      days,
      months,
      years,
    })
  }
  formatDate = () => {
    const { day, month, year } = this.state;
    if (day && month && year) {
      let monthNo = moment().month(month).format("MM");
      let date = `${year}-${monthNo}-${day}`
      let formattedDate = moment(date, 'YYYY-MM_D').format('YYYY-MM-DD');
      this.setState({
        date: formattedDate
      })
    }
  }
  handleTime = (time) => {
    this.setState({
      time,
    })
  }
  handleDate = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => { this.formatDate() })
  }
  // handleStep = (step) => {
  //   this.setState({
  //     currentStep: step
  //   })
  // }
  userSearch = async (e) => {
    const user = e.target.value;
    const type = e.target.name.toUpperCase();
    const query = `{
      searchUsers(type: ${type}, name: "${user}"){
        _id
        first_name,
        last_name,
      }
    }`
    const data = await helper.apolloQuery(query);
    const { searchUsers } = data;
    await this.setState({
      searchResults: searchUsers,
      userType: type
      // response: true,
    })
  }
  fetchChildren = async (id) => {
    const query = `{
      children(parent_id: "${id}"){
        _id
        first_name,
        last_name,
        subscriptions{
          id,
          package{
            name
          }
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    const { children } = data;
    await this.setState({
      children: children || []
    })
  }
  fetchActivities = async () => {
    const query = `{
      activities(includeCenter:true){
        name
        _id,
        description,
        duration{
          unit,
          time,
        },
        category{
          name
        },
        ageGroup{
          min,
          max
        },
      }
    }`
    const data = await helper.apolloQuery(query);
    const { activities } = data;
    await this.setState({
      activities,
    })
  }
  selectParent = (user) => {
    this.setState({
      parent: user,
      searchResults: [],
      userType: ''
    }, () => {
      this.fetchChildren(user._id)
    })
  }
  selectEducator = (user) => {
    this.setState({
      educator: user,
      searchResults: [],
      userType: ''
    })
  }
  selectChild = (child) => {
    this.setState({
      // childId: id
      child
    })
  }
  setDuration = (duration) => {
    this.setState({
      duration
    })
  }
  removeParent = () => {
    this.setState({
      parent: null,
      childId: null,
      userType: '',
      children: []
    })
  }
  removeEducator = () => {
    this.setState({
      educator: null,
      userType: '',
    })
  }
  selectActivity = (selectedActivity) => {
    this.setState({
      selectedActivity
    })
  }

  closeModal = () => {
    this.setState({
      modal: false
    }, () => {
      this.props.history.push({
        pathname: `/sessions/booked`
      })
    })
  }

  bookSession = (e) => {
    e.preventDefault();
    const token = `Bearer ${localStorage.getItem('access_token')}`;

    const { parent, child, educator, selectedActivity, date, time, duration } = this.state;
    const { _id: parentId } = parent;
    const { _id: childId, subscriptions } = child;
    const { _id: educatorId } = educator;
    const { _id: activityId } = selectedActivity;
    const subscriptionId = subscriptions ? subscriptions._id : '';

    const data = subscriptions ? {
      educator: educatorId,
      parent: parentId,
      child: childId,
      activity: activityId,
      subscription: subscriptionId,
      date: moment(date).format('YYYY-MM-DD'),
      time: moment(time).format('HH:mm'),
      duration: {
        time: duration,
        unit: "h"
      }
    }
      :
      {
        educator: educatorId,
        parent: parentId,
        child: childId,
        activity: activityId,
        date: moment(date).format('YYYY-MM-DD'),
        time: moment(time).format('HH:mm'),
        duration: {
          time: duration,
          unit: "h"
        }
      }
    this.setState({
      makeResquest: true
    })
    fetch(`${baseUrl}/a/sessions`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        this.setState({
          makeResquest: false,
        })
        if (helper.range(200, 299).indexOf(response.status) !== -1) {
          return response.json();
        }
        throw response;
      })
      .then((responseJson) => {
        this.setState({
          successMessage: responseJson.message || "Session has been created successfully",
          modal: true
        })
      })
      .catch((error) => {
        if (error) {
          error.json().then(e => {
            this.setState({
              error: e,
            })
          })
        }
      });
  }

  toType = (obj) => {
    return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
  }

  render() {
    const {
      // currentStep,
      parent,
      educator,
      childId,
      searchResults,
      userType,
      children,
      duration,
      day,
      month,
      year,
      days,
      months,
      years,
      time,
      date,
      activities,
      selectedActivity,
      child: selectedChild,
      makeResquest,
      successMessage,
      error,
      modal
    } = this.state;

    const { sessionType, currentStep, handleStep } = this.props;

    const p_first_name = parent && parent.first_name;
    const p_last_name = parent && parent.last_name;
    const e_first_name = educator && educator.first_name;
    const e_last_name = educator && educator.last_name;

    let dayOption,
      monthOption,
      yearOption;
    if (days && months && years) {
      dayOption = days.map((day, index) =>
        <option key={index}>{day}</option>
      )
      monthOption = months.map((month, index) =>
        <option key={index}>{month}</option>
      )
      yearOption = years.map((year, index) =>
        <option key={index}>{year}</option>
      )
    }

    const { name: activity_name, description: activity_description, duration: activity_duration, catergory: activity_category, ageGroup: activity_ageGroup } = selectedActivity;
    const activity_time = activity_duration && activity_duration.time;
    const activity_category_name = activity_category ? activity_category.name : 'n/a';
    const activity_age_bracket = activity_ageGroup ? `Ages ${activity_ageGroup.min}-${activity_ageGroup.max}` : 'n/a';
    const activity_formatted_duration = activity_time === 1 ? `${activity_time} Hour` : `${activity_time} Hours`;
    const formatted_duration = duration === 1 ? `${duration} Hour` : `${duration} Hours`;
    return (
      <React.Fragment>
        <FormSteps currentStep={currentStep} sessionType={sessionType} />
        <div className="main-form">
          {
            currentStep === 1 &&
            <div className="step-one">
              <div className="search-input">
                <span>Select parent</span>
                {
                  parent ?
                    <div className="selected-parent">
                      <div className="badge">{helper.createBadge(p_first_name, p_last_name)}</div>
                      <p>{p_first_name} {p_last_name}</p>
                      <img src={close_button} alt="" onClick={this.removeParent} />
                    </div>
                    :
                    <input type="text" placeholder="type name of parent" name="parent" onChange={this.userSearch} autoComplete="off" />
                }
                {
                  userType === 'PARENT' && searchResults &&
                  <div className="auto-complete">
                    {
                      searchResults.map(user => {
                        const { first_name, last_name, _id } = user;
                        const badge = helper.createBadge(first_name, last_name)
                        return (
                          <div className="parent" key={_id} onClick={() => this.selectParent(user)}>
                            <div className="badge">{badge}</div>
                            <p>{first_name} {last_name}</p>
                          </div>
                        )
                      })
                    }
                  </div>
                }
              </div>
              {
                parent &&
                <div className="children-div">
                  <span>Select child</span>
                  {
                    children.length > 0 && children.map(child => {
                      const { _id, first_name, last_name, subscriptions } = child;
                      const badge = helper.createBadge(first_name, last_name);
                      return (
                        <React.Fragment key={_id}>
                          <div className={selectedChild && _id === selectedChild._id ? "child-div selected" : "child-div"} onClick={() => this.selectChild(child)}>
                            <div className="check">
                              <div className="inner"></div>
                            </div>
                            <div className="line"></div>
                            <div className="badge">{badge}</div>
                            <p>{first_name} {last_name}</p>
                          </div>
                          {
                            _id === childId && subscriptions &&
                            <div className="info-div">
                              Samuel has an Unlimited Maker pass &amp; a
                              Premium Membership plan.
                            </div>
                          }
                        </React.Fragment>
                      )
                    })
                  }
                </div>
              }
              <button className="button-orange" onClick={() => handleStep(2)} disabled={!(parent && selectedChild) ? true : false}>next</button>
            </div>
          }
          {
            currentStep === 2 && sessionType === 'duration' &&
            <div className="step-two">
              <div className="content">
                <div className="duration">
                  <p>Select Duration of Activity</p>
                  <div className="options">
                    <div className={duration === 1 ? "option selected" : "option"} onClick={() => this.setDuration(1)}>
                      <div className="check">
                        <div className="inner"></div>
                      </div>
                      <p>1 hour</p>
                    </div>
                    <div className={duration === 2 ? "option selected" : "option"} onClick={() => this.setDuration(2)}>
                      <div className="check">
                        <div className="inner"></div>
                      </div>
                      <p>2 hours</p>
                    </div>
                    <div className={duration === 3 ? "option selected" : "option"} onClick={() => this.setDuration(3)}>
                      <div className="check">
                        <div className="inner"></div>
                      </div>
                      <p>3 hours</p>
                    </div>
                    <div className={duration === 4 ? "option selected" : "option"} onClick={() => this.setDuration(4)}>
                      <div className="check">
                        <div className="inner"></div>
                      </div>
                      <p>4 hours</p>
                    </div>
                  </div>
                </div>
                <div className="date">
                  <p>Select Date</p>
                  <div className="date-inputs">
                    <select name="day" value={day} onChange={this.handleDate}>
                      <option style={{ display: 'none' }}>Day</option>
                      {dayOption}
                    </select>
                    <select name="month" value={month} onChange={this.handleDate}>
                      <option style={{ display: 'none' }}>Month</option>
                      {monthOption}
                    </select>
                    <select name="year" value={year} onChange={this.handleDate}>
                      {yearOption}
                    </select>
                  </div>
                </div>
                <div className="time">
                  <p>Select Time</p>
                  <DatePicker
                    selected={time}
                    onChange={this.handleTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    dateFormat="h:mm aa"
                    timeCaption="Time"
                    placeholderText="Start Time"
                    className="input-style"
                    onFocus={(e) => e.target.readOnly = true}
                    onChangeRaw={(e) => e.preventDefault()}
                  />
                </div>
              </div>
              <div className="btn-div">
                <button className="button-outline-grey" onClick={() => handleStep(1)}>Previous</button>
                <button className="button-orange" onClick={() => handleStep(3)} disabled={!(duration && date && time)}>Next</button>
              </div>
            </div>
          }{
            currentStep === 2 && sessionType === 'activity' &&
            <div className="step-three">
              <p className="title">Available activities ({activities.length})</p>
              <div className="activities">
                {
                  activities.map(activity => {
                    const { _id, name, description, duration, catergory, ageGroup } = activity;
                    const { time } = duration;
                    const categoryName = catergory ? catergory.name : 'n/a';
                    const ageBracket = ageGroup ? `Ages ${ageGroup.min}-${ageGroup.max}` : 'n/a';
                    const activityDuration = time === 1 ? `${time} Hour` : `${time} Hours`;
                    return (
                      <div className={selectedActivity && selectedActivity._id === _id ? "option selected" : "option"} key={_id} onClick={() => this.selectActivity(activity)}>
                        <div className="activity-top">
                          <div className="check">
                            <div className="inner"></div>
                          </div>
                          <div className="activity-name">
                            <h5>{name}</h5>
                            <p>Duration • {activityDuration}</p>
                          </div>
                        </div>
                        <div className="activity-bottom">
                          <p>{description}</p>
                          <div className="tags">
                            <div className="tag">
                              <img src={subject_icon} alt="" />
                              {categoryName}
                            </div>
                            <div className="tag">
                              <img src={age_icon} alt="" />
                              {ageBracket}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              <div className="btn-div">
                <button className="button-outline-grey" onClick={() => handleStep(1)}>Previous</button>
                <button className="button-orange" onClick={() => handleStep(3)} disabled={Object.keys(selectedActivity).length === 0}>Next</button>
              </div>
            </div>
          }
          {
            currentStep === 3 && sessionType === 'duration' &&
            <div className="step-four">
              <div className="content">
                <div className="option">
                  <div className="activity-top">
                    <div className="activity-name">
                      <h5>{formatted_duration} booked</h5>
                      <p>Duration • {formatted_duration}</p>
                    </div>
                  </div>
                  <div className="activity-bottom">
                    <p>{activity_description}</p>
                    <div className="tags">
                      <div className="tag">
                        <img src={subject_icon} alt="" />
                        {activity_category_name}
                      </div>
                      <div className="tag">
                        <img src={age_icon} alt="" />
                        {activity_age_bracket}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-input">
                  <span>Assign Educator</span>
                  {
                    educator ?
                      <div className="selected-parent">
                        <div className="badge">{helper.createBadge(e_first_name, e_last_name)}</div>
                        <p>{e_first_name} {e_last_name}</p>
                        <img src={close_button} alt="" onClick={this.removeEducator} />
                      </div>
                      :
                      <input type="text" placeholder="type name of educator" name="educator" onChange={this.userSearch} autoComplete="off" />
                  }
                  {
                    userType === 'EDUCATOR' && searchResults &&
                    <div className="auto-complete">
                      {
                        searchResults.map(user => {
                          const { first_name, last_name, _id } = user;
                          const badge = helper.createBadge(first_name, last_name)
                          return (
                            <div className="parent" key={_id} onClick={() => this.selectEducator(user)}>
                              <div className="badge">{badge}</div>
                              <p>{first_name} {last_name}</p>
                            </div>
                          )
                        })
                      }
                    </div>
                  }
                </div>
              </div>
              <div className="btn-div">
                <button className="button-outline-grey" onClick={() => handleStep(2)}>Previous</button>
                <button className="button-orange" disabled={!educator} onClick={this.bookSession}>BOOK SESSION</button>
              </div>
            </div>
          }
          {
            currentStep === 3 && sessionType === 'activity' &&
            <div className="step-two">
              <div className="content">
                <div className="option b-m">
                  <div className="activity-top">
                    <div className="activity-name">
                      <h5>{activity_name}</h5>
                      <p>Duration • {activity_formatted_duration}</p>
                    </div>
                  </div>
                  <div className="activity-bottom">
                    <p>{activity_description}</p>
                    <div className="tags">
                      <div className="tag">
                        <img src={subject_icon} alt="" />
                        {activity_category_name}
                      </div>
                      <div className="tag">
                        <img src={age_icon} alt="" />
                        {activity_age_bracket}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="date">
                  <p>Select Date</p>
                  <div className="date-inputs">
                    <select name="day" value={day} onChange={this.handleDate}>
                      <option style={{ display: 'none' }}>Day</option>
                      {dayOption}
                    </select>
                    <select name="month" value={month} onChange={this.handleDate}>
                      <option style={{ display: 'none' }}>Month</option>
                      {monthOption}
                    </select>
                    <select name="year" value={year} onChange={this.handleDate}>
                      {yearOption}
                    </select>
                  </div>
                </div>
                <div className="time">
                  <p>Select Time</p>
                  {/* <select>
                    <option>Start Time</option>
                  </select> */}
                  <DatePicker
                    selected={time}
                    onChange={this.handleTime}
                    showTimeSelect
                    showTimeSelectOnly
                    // minTime={new Date(openFormatted)}
                    // maxTime={new Date((closeFormatted)*1 - 1000*3600*2)}
                    timeIntervals={60}
                    dateFormat="h:mm aa"
                    timeCaption="Time"
                    placeholderText="Start Time"
                    className="input-style"
                    onFocus={(e) => e.target.readOnly = true}
                    onChangeRaw={(e) => e.preventDefault()}
                  />
                </div>
              </div>
              <div className="btn-div">
                <button className="button-outline-grey" onClick={() => handleStep(2)}>Previous</button>
                <button className="button-orange" onClick={() => handleStep(4)} disabled={!(date && time)}>Next</button>
              </div>
            </div>
          }
          {
            currentStep === 4 &&
            <div className="step-four">
              <div className="content">
                <div className="option">
                  <div className="activity-top">
                    <div className="activity-name">
                      <h5>{activity_name}</h5>
                      <p>Duration • {activity_formatted_duration}</p>
                    </div>
                  </div>
                  <div className="activity-bottom">
                    <p>{activity_description}</p>
                    <div className="tags">
                      <div className="tag">
                        <img src={subject_icon} alt="" />
                        {activity_category_name}
                      </div>
                      <div className="tag">
                        <img src={age_icon} alt="" />
                        {activity_age_bracket}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-input">
                  <span>Assign Educator</span>
                  {
                    educator ?
                      <div className="selected-parent">
                        <div className="badge">{helper.createBadge(e_first_name, e_last_name)}</div>
                        <p>{e_first_name} {e_last_name}</p>
                        <img src={close_button} alt="" onClick={this.removeEducator} />
                      </div>
                      :
                      <input type="text" placeholder="type name of educator" name="educator" onChange={this.userSearch} autoComplete="off" />
                  }
                  {
                    userType === 'EDUCATOR' && searchResults &&
                    <div className="auto-complete">
                      {
                        searchResults.map(user => {
                          const { first_name, last_name, _id } = user;
                          const badge = helper.createBadge(first_name, last_name)
                          return (
                            <div className="parent" key={_id} onClick={() => this.selectEducator(user)}>
                              <div className="badge">{badge}</div>
                              <p>{first_name} {last_name}</p>
                            </div>
                          )
                        })
                      }
                    </div>
                  }
                </div>
              </div>
              <div className="btn-div">
                <button className="button-outline-grey" onClick={() => handleStep(3)}>Previous</button>
                <button className="button-orange" disabled={!educator} onClick={this.bookSession}>BOOK SESSION</button>
              </div>
            </div>
          }
          {
            makeResquest &&
            <div className="loader-div">
              <img src={spinner} alt="" />
            </div>
          }
          {
            error && this.toType(error) === this.toType([]) ?
              error.map(item => {
                const { message } = item;
                return (
                  <div className="loader-div">
                    <p className="error-text">{message}</p>
                  </div>
                )
              })
              :
              <div className="loader-div">
                <p className="error-text">{error && error.message}</p>
              </div>
          }
          <Modal show={modal} handleClose={this.closeModal}>
            <div className="popup">
              <img src={success_icon} alt="" />
              <p>{successMessage}</p>
              <button className="button-green" onClick={this.closeModal}>Ok</button>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(Forms);
